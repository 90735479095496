import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import { EASE, HIDE, SHOW } from '../../../../base/helpers/style'
import store from '../../../../base/services/store'
import emitter from '../../../../base/utils/emitter'
import images from '../../../../configs/images'
import { IFlashcard } from '../../../../liumus-interfaces/courses'
import models from '../../../../models'
import services from '../../../../services'
import { Connector } from '../connector'
import { FlashcardAnswer } from './flashcard-a'
import { FlashcardQuestion } from './flashcard-q'
import { SimplePlus } from './simple-plus'
import { Toolbar } from './toolbar'

export const CourseFlashcard = (data: IFlashcard, options: any = {}) => {
    const translations = services.translations.all()
    const MEMORIZE = translations['MEMORIZE']
    const MEMORIZING = translations['MEMORIZING']
    const MEMORIZED = translations['MEMORIZED']
    const base = Div()
    const face = FlashcardQuestion(data.q, options)
    const back = FlashcardAnswer(data.a, options)
    const plus = SimplePlus()
    const toolbar = Toolbar([face, back])
    const rotate = Img(images.ICONS.FLIP, { width: 36 })
    const memorize = Div(MEMORIZE)
    const remove = Img(images.ICONS.DELETE, { width: 36, height: 24 })
    const holder = Div()
    holder.append(face, back)
    const buttons = Div()
    buttons.append(rotate, memorize, remove )
    base.append(plus, toolbar, holder,buttons)

    remove.el.onclick = () => {
        base.emit('remove')
        base.remove()
    }
    remove.style(HIDE)
    rotate.style(HIDE)
    toolbar.style(HIDE)
    base.el.addEventListener('mouseover', () => {
        rotate.style(SHOW)
        if (options.roles === 'user') return
        remove.style(SHOW)
        toolbar.style(SHOW)
    })
    base.el.addEventListener('mouseleave', () => {
        remove.style(HIDE)
        rotate.style(HIDE)
        toolbar.style(HIDE)
    })

    services.flashcards.isMemorized(data._id as string).then(level => {
        if (level < 0) {
            memorize.text('Add to my cards')
            memorize.style({
                backgroundColor: '#0bc3b5',
            })
        // } else if (level > -1) {
        //     memorize.text(MEMORIZING)
        //     memorize.style({
        //         backgroundColor: '#03d7c6',
        //     })
        } else {
            const checkSvg = Img(images.ICONS.CHECK, { width: 24 })
            memorize.text('Added to the cards')
            memorize.append(checkSvg)
            memorize.style({
                display: 'flex',
                justifyContent: 'center',
                width: '70%',
                backgroundColor: 'transparent',
                color: 'gray',
                alignItems: 'center',
                flexShrink: '0',
            })
        }
    })

    face.el.onclick = () => {
        if (options.roles === 'user') {
            flip()
        }
    }
    back.el.onclick = () => {
        if (options.roles === 'user') {
            flipBack()
        }
    }

    memorize.el.onclick = async () => {
        const level = await services.flashcards.isMemorized(data._id as string)
        if (level > -1) {
            return
            await services.flashcards.forget(data._id as string)
            memorize.text(MEMORIZE)
            memorize.style({
                backgroundColor: '#03d7c6',
            })

        } else {
            await services.flashcards.memorize(data._id as string, options.courseId)
            const checkSvg = Img(images.ICONS.CHECK, { width: 24 })
            memorize.text('Added to the cards')
            memorize.append(checkSvg)
            memorize.style({
                display: 'flex',
                alignItems: 'center',
                width: '70%',
                flexShrink: '0',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                color: 'gray',
            })
        }
    }

    plus.el.onclick = async () => {
        const flashcardContent = models.flashcard.create()
        const response = await services.flashcards.create(flashcardContent)
        const newFlashcard = CourseFlashcard(response.data)
        base.parent.appendAfter(base, newFlashcard)
    }
    toolbar.cssClass({
        position: 'absolute',
        top: '-40px',
        width: '100%',
    })
    buttons.cssClass({
        position: 'absolute',
        bottom: '-35px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // display: 'grid',
       alignItems: 'center',

        // gridTemplateColumns: '1fr 1fr 1fr',
    })
    memorize.cssClass({
        // width: '60%',
        marginTop: '0px',
        borderRadius: '20px',
        padding: '10px 20px',
        color: '#fff',
        backgroundColor: '#03d7c6',
        cursor: 'pointer',
        fontSize: '14px',
        // fontWeight: 'bold',
        letterSpacing: '1px',
        display: 'none',
        ...EASE(.16),

        // transform: 'translateX(-50%)',
        '&:active': {
            backgroundColor: '#0bc3b5',
        }
    })
    remove.cssClass({
        cursor: 'pointer',
        margin: '0 10px',   
        ...EASE(.16),

    })
    plus.cssClass({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '-58px',
        top: '50%',
        transform: 'translateY(-50%) scale(1)',
    })
    holder.cssClass({
        borderRadius: '1rem',
        position: 'relative',
        perspective: '2000px',
        cursor: 'pointer',
        height: '500px',
        width: '300px',
        marginBottom: '1rem',
        ...EASE(.16),
    })
    rotate.cssClass({
        cursor: 'pointer',
        margin: '0 10px',   
        ...EASE(.16),

    })
    base.cssClass({
        position: 'relative',
        margin: '30px 80px 80px 0px',
        marginBottom: '1rem',
    })
    if (options.roles === 'user') {
        plus.style({ display: 'none' }, 10)
        toolbar.style({ display: 'none' })
        remove.style(HIDE)
        memorize.style({ display: 'block' })
    }
    function flip() {
        face.style({ transform: 'rotateY(180deg)' })
        back.style({ transform: 'rotateY(360deg)' })
        rotate.el.onclick = flipBack
    }

    function flipBack() {
        face.style({ transform: 'rotateY(0deg)' })
        back.style({ transform: 'rotateY(180deg)' })
        rotate.el.onclick = flip
    }

    rotate.el.onclick = flip

    return Object.assign(base, {
        getValue: () => {
            const q = face.el.innerHTML
            const a = back.el.innerHTML
            const last_change = new Date().toISOString()
            return { ...data, q, a, last_change }
        },
        update: async () => {
            const { q, a } = base.getValue()
            await services.flashcards.update({ ...data, q, a, last_change: new Date().toISOString() })
        },
        getId: () => data._id
    })
}