import { Div } from '../../../../base/components/native/div'
import { Y, X } from '../../../../base/helpers/style'
import { IRouteParams } from '../../../../base/lib/router'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import { Loading } from '../../../shared/loading'
import { AttemptsOverview } from './course-attempt-overview'
import { AttemptOfUser } from './course-attempt-user'
import { CourseAttempteeItem } from './course-attemptee-item'
import { CourseAttemptees } from './course-attemptees'
import { SubPageContents } from './sub-page-contents'

export const CourseAttempts = () => {

    const base = Div()
    const contents = SubPageContents()
    const attemptees = CourseAttemptees()
    base.append(attemptees, contents)
    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        ...Y(0),
        ...X(window.innerWidth),
        display: 'flex'
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to?.includes('/course')) base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
        if (to?.includes('/settings')) base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        contents.empty()
    }

    async function enter({ params, from, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        contents.append(Loading())
        attemptees.append(Loading())
        const quiz = await services.quizzes.getByCourse(data.course._id)
        const attempts = await services.quizzes.getAttempts(quiz.data?.items?.[0]._id) as any
        const users_ids = attempts.data?.items?.map((a: any) => a.user_id)
        const users = await services.people.getMany(users_ids) as any
        attemptees.empty()

        const items = [{ email: '', name: 'Overall results', id: '' } as any, ...users.data?.items]
        const foundInUrl = location.search.split('attempted-user=')[1] || ''
        const found = items.find((u: any) => u.email === foundInUrl) || items[0]
        found.isSelected = true
        
        let selected: any = null
        items.forEach((u: any, index) => {
            const item = CourseAttempteeItem(u)
            attemptees.append(item)
            item.el.onclick = () => {
                if (selected) selected.unselect()
                selected = item
                selected.select()
                history.pushState({}, '', `/courses/${params.id}/attempts?attempted-user=${u.id || u.email}`)
                const foundAttempt = attempts?.data?.items?.find((a: any) => a.user_id === u._id) || attempts?.data?.items
                load(foundAttempt)
            }
            if (u.isSelected) {
                selected = item
                selected.select()
                const foundAttempt = attempts?.data?.items?.find((a: any) => a.user_id === u._id) || attempts?.data?.items
                load(foundAttempt)
            }
        })
        
        function load(attempt: any[] | any) {
            contents.empty()
            if (!attempt) return
            if (attempt.length > 1) {
                const attemptsOverview = AttemptsOverview(quiz.data?.items?.[0].questions, attempt)
                contents.append(attemptsOverview)
            } else {
                const attemptedByUser = AttemptOfUser(quiz.data?.items?.[0].questions, attempt)
                contents.append(attemptedByUser)
            }
        }
    }

    return Object.assign(base, { enter, exit })
}