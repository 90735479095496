import store from '../base/services/store'
import { IFlashcard, ICoursePayload, IFlashcardPayload } from '../liumus-interfaces/courses'
import api from './api'

async function create(data: IFlashcardPayload) {
    console.log('in create', data);
    
    return api.post('/api/flashcards', data)
}

async function getAll() {
    return api.get('/api/flashcards')
}

async function getOne(id: string) {
    return api.get(`/api/flashcards/${id}`)
}

async function update(data: IFlashcard) {
    return api.put(`/api/flashcards/${data._id}`, data)
}

async function forget(flashcardId: string) {
    await api.delete('/api/flashcards/' + flashcardId + '/memorize')
    const allMemorizeds = store.get('memorized-cards')
    store.set('memorized-cards', allMemorizeds.filter((id: string) => id !== flashcardId))
}

async function changeLevel(flashcardId: string, courseId: string, newLevel: number) {
    await api.put('/api/flashcards/' + flashcardId + '/memorize', { newLevel, courseId })
}

async function memorize(flashcardId: string, courseId: string) {
    await api.post('/api/flashcards/' + flashcardId + '/memorize', { courseId })
    let allMemorizeds = store.get('memorized-cards')
    // todo: try catch and retry
    if (!allMemorizeds) {
        allMemorizeds = await _getAllMemorizedCards()
    }
    store.set('memorized-cards', [...allMemorizeds, flashcardId])
}

async function isMemorized(flashcardId: string) {
    let allMemorizeds = store.get('memorized-cards')
    if (!allMemorizeds) {
        allMemorizeds = await _getAllMemorizedCards()
        store.set('memorized-cards', allMemorizeds)
    }
    const found = allMemorizeds.find((item: any) => item.flashcardId === flashcardId)
    return found?.level ?? -1
}

async function _getAllMemorizedCards() {
    const response = await api.get<any>(`/api/flashcards?memorized=true`)
    return response?.data?.items
}

async function getToReview() {
    const response = await api.get<any>(`/api/flashcards?toReview=true`)
    return response?.data?.items as { id: string, flashcardId: string }[]
}

navigator.serviceWorker.addEventListener('message', async function (event) {
    console.log('in main thread', event.data);
    
    if (event.data.type === 'RUN_FUNCTION') {
        console.log('in main thread', event.data)

        const result = await getToReview()
        console.log('result', result, event.source);
        
     //  if (result.length) event.source?.postMessage({ type: 'FUNCTION_RESULT', result: result.length })
    }
})

export default {
    create,
    getAll,
    getOne,
    update,
    forget,
    memorize,
    isMemorized,
    getToReview,
    changeLevel
}