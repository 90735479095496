import { getAdditionalUserInfo } from 'firebase/auth'
import { Base } from '../../../base/components/base'
import { Div } from '../../../base/components/native/div'
import { EASE } from '../../../base/helpers/style'
import ldb from '../../../base/lib/ldb'
import emitter from '../../../base/utils/emitter'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import authUser from '../../../services/firebase/auth-user'
import { attemptLogout } from '../../../services/firebase/auth-google-signin-popup'
import store from '../../../base/services/store'
import { urlBase64ToUint8Array } from '../../../base/utils/base64'
import { Switch } from '../../shared/switch/switch'
import router, { IRouteParams } from '../../../base/lib/router'
import { PageTitle } from '../../shared/page-title'
import { HeaderLink } from '../../shared/header-link'
import device from '../../../base/helpers/device'

export const MenuPage = () => {

    const base = Base()
    const contents = Div()
    const title = PageTitle('Menu')
    const notifications = Div('Notifications')
    const notificationsSwitch = Switch()
    notifications.append(notificationsSwitch)
    const login = Div('Login')
    const loggedIn = Div()
    const logout = Div('Logout')
    contents.append(notifications, login, loggedIn, logout)
    base.append(title, contents)
    const homeLink = HeaderLink('/', 'Home / ')
    if (!device.isMobile()) title.prepend(homeLink)

    emitter.on('logged-in', (user: any) => {
        login.style({ display: 'none' })
        loggedIn.text(`Welcome ${user.displayName}!<br>${user.email}`)
        loggedIn.style({ display: 'block' })
        logout.style({ display: 'block' })
    })
    login.el.onclick = () => router.goto('/login')
    // login.el.onclick = () => attemptLogin()
    logout.el.onclick = () => {
        attemptLogout()
        store.set('roles', undefined)
        // clear indexedDB
        login.style({ display: 'block' })
        loggedIn.style({ display: 'none' })
        logout.style({ display: 'none' })
        router.goto('/')
    }

    console.log(Notification.permission)
    navigator.serviceWorker.ready.then(registration => {
        registration.pushManager.getSubscription().then(subscription => {
            notificationsSwitch.setValue(!!subscription)
        })
    })

    notificationsSwitch.on('on-change', async () => {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                // Get push subscription
                navigator.serviceWorker.ready.then(registration => {
                    console.log(notificationsSwitch.getValue())

                    if (notificationsSwitch.getValue() === true) {

                        registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array('BOPqz9jnNzp428InpezCDCJuiK6hQ5Outk3bAV8SxUEunxY1KJYqwfI3Ciqrp6Grhh-2rhQnNXGIhiSwndfZh2w')
                        }).then(subscription => {
                            // Send the subscription object to the server
                            fetch('/api/notifications/subscribe', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(subscription)
                            })
                        })
                    } else {
                        console.log('unsubscribing')

                        registration.pushManager.getSubscription().then(subscription => {
                            console.log('subscription', subscription)

                            if (subscription) {
                                // Unsubscribe from push notifications
                                subscription.unsubscribe().then(success => {
                                    if (success) {
                                        // Notify the backend to remove the subscription
                                        fetch('/api/notifications/unsubscribe', {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(subscription)
                                        }).then(response => {
                                            if (response.ok) {
                                                console.log('Successfully unsubscribed and removed from backend.')
                                            } else {
                                                console.error('Failed to communicate with backend.')
                                            }
                                        })
                                    } else {
                                        console.error('Failed to unsubscribe.')
                                    }
                                })
                            } else {
                                console.log('No subscription found.')
                            }
                        })

                    }


                })
            } else {
                notificationsSwitch.setValue(false)
            }
        })



    })
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    notifications.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    title.cssClass({ margin: '0 0 21px 0' })
    login.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    loggedIn.cssClass({ margin: '0 0 21px 0', fontSize: '16px' })
    logout.cssClass({ margin: '0 0 21px 0', cursor: 'pointer' })
    contents.cssClass({
        fontSize: '20px',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: '70px',
        paddingBottom: '50px',
        paddingTop: '18px',
        paddingRight: '50px',
    })

    notifications.cssClass({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    })

    async function exit({ to = '' }: IRouteParams) {
        console.log('exiting menu page', { to });
        
        base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
        if (to === '/login') base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to == '/') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }
    async function enter({ from, data, query, params }: IRouteParams) {
        console.log('entering menu page', { from, data, params, query })
        
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        const user = await authUser.getUser()
        if (user !== undefined && user !== null) {
            login.style({ display: 'none' })
            loggedIn.style({ display: 'block' })
            logout.style({ display: 'block' })
            loggedIn.html(`Welcome ${user.displayName}! <br><span style="color:gray">${user.email}</span>`)
        } else {
            login.style({ display: 'block' })
            loggedIn.style({ display: 'none' })
            logout.style({ display: 'none' })
        }
    }

    return Object.assign(base, { enter, exit })
}