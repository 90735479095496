import { Base } from '../../../base/components/base'
import { EASE } from '../../../base/helpers/style'
import { SwitchSlider } from './switch-slider'


export const Switch = (state = false) => {

    const base = Base('div')
    const slider = SwitchSlider()
    base.append(slider)
    base.on('mounted', () => {
        const { width } = base.el.getBoundingClientRect()
        slider.setPositions([0, width / 2 + 1])
        if (state === true) {
            slider.move(1)
        }
    })

    base.el.onclick = () => {
        state = !state
        slider.move(state ? 1 : 0)
        base.style({
            backgroundColor: state? '#00c591': '#ccc'
            
        })
        base.emit('on-change')
    }

    slider.on('fix-on', (i: number) => {
        if (i == 1) {
            state = true;
            base.style({
                backgroundColor: '#00c591'
            })
        } else {
            state = false
            base.style({
                backgroundColor: '#ccc'
            })
        }
        base.emit('on-change')

    })

    base.append(slider)

    base.cssClass({
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'relative',
        borderRadius: '20px',
        border: '1px solid #ffffff8c',
        height: '36px',
        zIndex: '0',
        width: '74px',
        backgroundColor: '#ccc',
        ...EASE(.24)
        // overflow: 'hidden'
    })

    return Object.assign(
        base,
        {
            getValue(): boolean {
                return state
            },
            reset() {
                state = false
                slider.move(0)
            },
            setValue(v: boolean) {
                state = v
                slider.move(v ? 1 : 0)
                base.style({
                    backgroundColor: state? '#00c591': '#ccc'
                    
                })
            }
        }
    )
}