import { ICourse, ICoursePayload } from '../liumus-interfaces/courses'
import api from './api'


async function generateFlashcards(data: string) {
    return api.post('/api/ai/extract-flashcards', { content: data })
}

async function createFullCourse(data: ICoursePayload) {
    return api.post('/api/ai/create-full-course', { content: data })
}




export default {
    generateFlashcards,
    createFullCourse,
}