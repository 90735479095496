import { App } from './app'
import router from './base/lib/router'
import { PASSIVE } from './base/utils/passive-support'
import db from './services/db'

db.init().then(() => {
	document.body.appendChild(App().el)
	console.log(location);
	
	router.goto(location.pathname + location.search)
	// Todo: handle backs // courses/xyz
	// get list of flashcards for course to check
	// const itemsToReview = await services.flashcards.getToReview() as any[]
	// Todo: wait for auth then store.set('roles', roles)
})
document.addEventListener('touchstart', () => false, PASSIVE)
