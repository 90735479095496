import ldb from '../base/lib/ldb'
import state from '../base/services/state'
import liumusStrings from '../liumus-strings'

function all(): {[key: string]: string} {
    const [getLocale] = state.use<'en' | 'uk'>('locale', ldb.get('liumus-locale') || 'en')
    return liumusStrings[getLocale()]
}

export function t(key:string) {
    const translations = all()
    return translations[key]
}

export default {
    all
}