import { Div } from '../../../../base/components/native/div'
import { ABSOLUTE, CENTER, WH } from '../../../../base/helpers/style'
import helpers from '../../../../helpers'

export const FlashcardQuestion = (question: string, options: any = {}) => {

    const body = question//.replace(/<img/g, '<img style="max-width: 100%"')
    const pure = helpers.html.cleanPaste(body).replace(/<img/g, '<img style="max-width: 100%"').replace(/<li/g, '<li style="list-style: none"')
    const base = Div(pure)
    if (options.roles === 'user') {
        setTimeout(() => {
            base.el.setAttribute('contenteditable', 'false')
        }, 100)
        base.el.setAttribute('contenteditable', 'false')
    }
    base.el.onclick = () => {
        if (base.el.innerHTML.length < 20) {
            helpers.html.selectAll(base.el)
        }
    }
    base.on('mounted', () => {
        if (base.el.offsetHeight < base.el.scrollHeight) {
            base.el.style.justifyContent = 'flex-start';
          }
    })
    base.el.addEventListener('paste', function (e) {
        e.preventDefault()  
        let clipboardData = e.clipboardData
        let pastedData = clipboardData?.getData('text/html') || ''  
        const html = helpers.html.cleanPaste(pastedData)
        helpers.html.handlePaste(html)
    })

    // base.el.onclick = selectAll.bind(null, base.el)

    base.cssClass({
        ...ABSOLUTE,
        ...WH('100%'),
        ...CENTER,
        flexDirection: 'column',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(0deg)',
        transition: 'transform 0.5s',
        backgroundColor: 'white',
        borderRadius: '1.5rem',
        boxShadow: 'rgb(0 0 0 / 9%) 0px 0px 3rem',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '1rem',
    })

    return base
}

