import { Div } from '../../../../base/components/native/div'
import { Y, X } from '../../../../base/helpers/style'
import ldb from '../../../../base/lib/ldb'
import { IRouteParams } from '../../../../base/lib/router'
import emitter from '../../../../base/utils/emitter'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import { ICourse } from '../../../../liumus-interfaces/courses'
import services from '../../../../services'
import { DButton } from '../../../shared/d-button'
import { DInput } from '../../../shared/d-input'
import { SubPageContents } from './sub-page-contents'

export const CourseSettings = () => {

    const base = SubPageContents()
    const title = DInput('Change course title', { placeholder: 'Course title' })
    const description = DInput('Change course description', { placeholder: 'Course description' })
    const duration = DInput('Specify the duration of the course (days)', { placeholder: 'Set duration' })
    const score = DInput('Define score needed for Certification', { placeholder: 'Set the minimum score' })
    const save = DButton('Save duration')
    base.append(title, description, duration,score, save)

    save.on('click', handleUpdate)
    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        ...Y(0),
        ...X(window.innerWidth),
    })
    

    let __course: ICourse
    async function handleUpdate() {
        if (!title.getValue()) return title.setError('Please enter a title')
        if (!description.getValue()) return description.setError('Please enter a description')
        if (!duration.getValue()) return duration.setError('Please enter a duration')
        if (!score.getValue()) return score.setError('Please enter a score')
        const payload = { title: title.getValue(), duration: parseInt(duration.getValue()) }
        await services.courses.update({ _id: __course._id, ...payload })
        emitter.emit('course-updated', { ...__course, ...payload })
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to?.includes('/course')) base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
        if (to?.includes('/settings')) base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ params, from, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        duration.setValue(data.course.duration || 120)
        title.setValue(data.course.title)
        __course = data.course
    }



    return Object.assign(base, { enter, exit })
}