import { Div } from '../../base/components/native/div'
import { IRouteParams } from '../../base/lib/router'
import helpers from '../../helpers'
import { PageContents } from '../shared/page-contents'


export const NotFound = () => {

    const base = Div('<b>404</b>Not found')
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    base.style({ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '6rem', color: '#ccc' , flexDirection: 'column'})

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        // if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}