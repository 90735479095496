import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import images from '../../../configs/images'

export const Logo = () => {

    const base = Div()
    const title = Div('Welcome to Liumus – The Future of Corporate Learning!')
    const logo = Img(images.RES.LOGO, { width: 226 })
    base.append(logo, title)

    title.cssClass({
        fontSize: '22px',
        fontWeight: '100',
        marginTop: '10px',
        color: 'gray',
        textAlign: 'center',
        lineHeight: '1.5',
    })
    base.cssClass({
        textAlign: 'center',
        margin: '60px auto 40px auto',
        display: 'block',
    })

    return base
}