import { IBaseComponent } from '../../../../base/components/base'
import { t } from '../../../../services/translations'
import { DButton } from '../../../shared/d-button'

export interface IActionButton extends IBaseComponent<'button'> {
    hide: () => void
    show: () => void
    saving: () => void
    saved: () => void
}
export const ActionButton = (title: string, action: () => void, options: any = {}) => {

    const base = DButton(title || t('SAVE'))
    base.el.onclick = action
    base.cssClass({
        height: '42px',
        width: 'fit-content',
        padding: '0px 20px',
        zIndex: '999',
        // display: 'none  ',
        margin: '0px 5px',
        '@media (max-width: 768px)': {
            // right: '20px',
        },
        '&:hover': {
            filter: 'brightness(0.97)',
        },
        '&:active': {
            boxShadow: 'none',
        },
    })
    if (options.color) base.cssClass({ backgroundColor: options.color })

    return Object.assign(base, {
        hide() {
            base.style({ display: 'none' })
        },
        show() {
            base.style({ display: 'block' })
        },
        saving() {
            base.text(t('SAVING'))
            base.el.disabled = true
        },
        saved() {
            base.text(t(title || 'SAVE'))
            base.el.disabled = false
        }
    })
}