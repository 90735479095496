import { Div } from '../../../base/components/native/div'
import { ICourse } from '../../../liumus-interfaces/courses'
import { CourseItemTitle } from './course-item-title'

export const CourseTitles = (items: ICourse[] = []) => {

    const base = Div()
    const titles = items.map((item: any) => CourseItemTitle(item))
    base.append(...titles)
    let selected = titles[0]
    // if (!device.isMobile()) {

    // if (selected) selected.select()
    // }

    titles.forEach((item: any, i: number) => {
        item.el.onclick = () => {
            if (selected) selected.deselect()
            item.select()
            selected = item
            base.emit('click')
        }
    })


    // match(window.location.pathname)
    // emitter.on('route-changed', match)
    // function match(url:string) {
    //     const regex = /courses\/([a-z0-9]+)/;
    //     const match = regex.exec(url);
    //     const id = match?.[1];
    //     if (id) {
    //         selected.deselect()
    //         const item = titles.find((t: any) => t.getId() == id)
    //         if (item) {
    //             item.select()
    //             selected = item
    //         }
    //     }
    // }
    base.cssClass({
    })

    return base
}