import { Div } from '../../../../base/components/native/div'
import router from '../../../../base/lib/router'
import emitter from '../../../../base/utils/emitter'
import { CourseOption } from './course-option'

export const CourseOptions = (mode = 'desktop') => {

    const base = Div()
    const contents = CourseOption('Contents', 'info')
    const learners = CourseOption('Learners', 'learners')
    const quiz = CourseOption('Quiz', 'quizzes')
    const attempts = CourseOption('Attempts', 'attempts')
    const settings = CourseOption('Settings', 'settings')

    const options = [contents, learners, quiz, attempts, settings]
    options.forEach(option => {
        option.on('select', (target: string) => {
            base.emit('select', target)
            handleSelect(target)
        })
    })
    // router.on('change', (r: any) => {
    //     if (mode === 'mobile') return
    //     handleSelect(r.path)
    // })
    const path = location.pathname.match(/courses\/\w+\/(\w+)/)?.[1]
    handleSelect(path || '')
    
    function handleSelect(path: string) {
        contents.unselect()
        learners.unselect()
        quiz.unselect()
        attempts.unselect()
        settings.unselect()
        switch (path) {
            case 'learners':
                return learners.select()
            case 'quizzes':
                return quiz.select()
            case 'attempts':
                return attempts.select()
            case 'settings':
                return settings.select()
            case 'info':
                return contents.select()
            case '':
                return contents.select()
        }
    }
    
    base.append(contents, learners, quiz, attempts, settings)
    base.cssClass({
        width: '200px',
        borderRight: mode === 'mobile' ? 'none' : '1px solid #ccc',
        paddingTop: '30px',
        position: 'absolute',
        top:  mode === 'mobile' ?'0':'75px',
        left: '0px',
        bottom: '10px',
        paddingLeft: '49px',
    })

    return Object.assign(base, {
        applyRoles: (roles: string[] = ['user']) => {
            contents.show()
            quiz.show()
            if (roles.includes('user')) {
                learners.hide()
                attempts.hide()
                settings.hide()
            } else {
                learners.show()
                attempts.show()
                settings.show()
            }
        }
    })
}