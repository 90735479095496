import device from '../base/helpers/device'
import { ABSOLUTE, EASE, HIDE, SHOW, X, Y } from '../base/helpers/style'
import { CS } from '../base/utils/styler'

const isMobile =  device.isMobile()
export default {
    PAGE_BASE_STYLE: {
        ...EASE(isMobile ? .16 : 0),
        ...HIDE,
        ...Y(isMobile ? 60 : 0),
        ...ABSOLUTE,
        top: 'env(safe-area-inset-top)',
        bottom: 'env(safe-area-inset-bottom)',
        zIndex: '9999',
        willChange: 'opacity,transform',
    },
    PAGE_EXIT_DOWN_STYLE: {
        ...HIDE,
        ...Y(isMobile ? 60 : 0),
    },
    PAGE_ENTER_STYLE: {
        ...SHOW,
        ...Y(0),
        ...X(0)
    },
    PAGE_EXIT_UP_STYLE: {
        ...HIDE,
        ...Y(isMobile ? -60 : 0),
    },
    PAGE_EXIT_LEFT_STYLE: {
        ...HIDE,
        ...X(isMobile ? -window.innerWidth : 0),
    },
    PAGE_EXIT_RIGHT_STYLE: {
        ...HIDE,
        ...X(isMobile ? window.innerWidth : 0),
    },
    PAGE_EXIT_DESKTOP: {
        ...HIDE,
        ...Y(0)
    },
    PAGE_ENTER_DESKTOP: {
        ...SHOW,
        ...Y(0)
    },
    LIST_ITEM_STYLE: {
        fontSize: '18px',
        fontWeight: '100',
        padding: '0 0 16px 0',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.8'
        }
    },
} as { [key: string]: CS }
