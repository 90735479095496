import { IKeys } from './keys'

const keyLabels: Partial<IKeys> = {
    COURSES: 'Курси',
    QUIZZES: 'Тести',
    FLASHCARDS: 'Флешкарти',
    PEOPLE: 'Люди',
    ANALYTICS: 'Аналітика',
    SETTINGS: 'Налаштування',
    CHANGE_LANGUAGE: 'Змінити мову',
    LOGIN: 'Увійти',
    PLEASE_WAIT: 'Будь ласка, зачекайте',
    LOGIN_WITH_GOOGLE: 'Увійти через Google',
    ADD_A_NEW_COURSE: 'Додати новий курс',
    CREATE_A_NEW_COURSE: 'Створити новий курс',
    SAVE: 'Зберегти',
    SAVING: 'Збереження',
    SAVED: 'Збережено',
    MEMORIZE: 'Запам\'ятати',
    MEMORIZING: 'Запам\'ятовування',
    MEMORIZED: 'Запам\'ятано',
    CREATED_ON: 'Створено',
    AT: 'о',
    ADD_SLIDE_WELCOME_MESSAGE: 'Натисніть, щоб створити новий слайд!',
    NEW_COURSE: 'Новий курс',
    NEW_QUIZ: 'Новий тест',
    TITLE: 'Назва',
    DESCRIPTION: 'Опис',
    CREATE: 'Створити',
    CLICK_TO_EDIT: 'Натисніть, щоб редагувати',
    SLIDE_CONTENT: 'Вміст слайда',
    NO_COURSES_AVAILABLE: 'Немає доступних курсів',
    NO_QUIZZES_AVAILABLE: 'Немає доступних тестів',
    NO_FLASHCARDS_AVAILABLE: 'Немає доступних флешкарт',
    NO_PEOPLE_AVAILABLE: 'Немає доступних людей',
    ADD_NEW_EMPLOYEE: 'Додати нового співробітника',
    NO_ANALYTICS_AVAILABLE: 'Немає доступної аналітики',
    NO_SETTINGS_AVAILABLE: 'Немає доступних налаштувань',
    NAME: 'Ім\'я',
    EMAIL: 'Електронна пошта',
    ROLE: 'Роль',
    PHONE: 'Телефон',
    REVIEW: 'Огляд',
}

export default keyLabels