import { Div } from '../../../../base/components/native/div'
import services from '../../../../services'

export const CourseAt = (at: string) => {

    const translations = services.translations.all()
    const createdOn = translations['CREATED_ON']
    const att = translations['AT']
    const base = Div(createdOn + ' ' + at.slice(0, -8).replace('T', ' ' + att + ' '))
    base.cssClass({
        fontSize: '14px',
        color: '#787878',
        marginBottom: '10px',
    })

    return base
}