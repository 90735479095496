import { Div } from '../../../../base/components/native/div'

export const FeatureTitle = (text: string) => {

    const base = Div(text)
    base.cssClass({
        fontSize: '24px',
        fontWeight: '100'
    })

    return base
}