import { Div } from '../../../../base/components/native/div'

export const CourseLearnersListItem = (item: { name: string, email: string }) => {

    const base = Div()
    const name = Div(item.name)
    const email = Div(item.email)
    base.append(name, email)

    base.cssClass({
        margin: '0 0 30px',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.8',
        }
    })
    name.cssClass({
        fontSize: '18px',
    })
    email.cssClass({
        color: 'gray',
        fontSize: '16px',
    })

    return base
}
