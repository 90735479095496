import { Div } from '../base/components/native/div'

export const View = () => {

    const base = Div() // Fragment later

    base.cssClass({
        position: 'relative',
        right: '0',
        height: 'calc(100vh)',// + env(safe-area-inset-bottom) + env(safe-area-inset-top)',
        overflow: 'hidden',
        width: '100vw',
        // maxWidth: '425px',
    })

    return base
}