import ldb from '../base/lib/ldb'
import { ICourseSlide } from '../liumus-interfaces/courses'
import services from '../services'

const translations = services.translations.all()
function create(): ICourseSlide {
    const now = new Date().toISOString()
    return {
        // type: 'slide',
        at: now,
        // lastChange: now,
        // usserId:  ldb.get('liumus-user')?.profile?.email, // Todo: change to id and service
        // _id: '',
        last_change: now,
        
        content: translations['CLICK_TO_EDIT']
    }
}
export default {
    create

}