import { Div } from '../../../../base/components/native/div'
import { IAnswer, IAttempt, IQuestion } from '../../../../liumus-interfaces/quiz'
import { AttemptQuestion } from './course-attempt-question'

export const AttemptOfUser = (questions: IQuestion[], attempt: IAttempt) => {

    const base = Div()
    const score = Div('Score: ' + attempt.score + '/' + attempt.total)
    base.append(score)
    questions.forEach((q, i) => {
        const answer = attempt.answers.find(a => a.id === q.id) as IAnswer
        const question = AttemptQuestion(q, [answer?.userAnswerIndex], i)
        base.append(question)
    })

    score.cssClass({
        fontSize: '24px',
        lineHeight: '10px',
        marginBottom: '30px',
        fontWeight: '100'
    })
    base.cssClass({

    })

    return base
}