import { Div } from '../base/components/native/div'
import { CENTER } from '../base/helpers/style'
import { DButton } from '../components/shared/d-button'
import { DInput } from '../components/shared/d-input'

export const LinkBox = (href = '' ) => {

    const base = Div()
    const input = DInput('Add link target')
    const confirm = DButton('Confirm')
    const cancel = DButton('Cancel')
    base.append(input, confirm, cancel)

    confirm.el.onclick = () => base.emit('confirm', input.getValue())
    cancel.el.onclick = () => base.emit('cancel')
    base.cssClass({
        display: 'grid',
        gridTemplateColumns: '3fr .7fr .7fr',
        alignItems: 'baseline',
        columnGap: '10px',
        position: 'absolute',
        width: '100%',
        left: '0',
        right: '0',
        top: '-11px',
        backgroundColor: '#ebebeb',
        padding: '0 10px',
        height: '68px',
        borderRadius: '20px'
    })
    cancel.cssClass({
        backgroundColor: '#f44336',
    })

    return base
}