import { Div } from '../../../base/components/native/div'
import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import { DButton } from '../../shared/d-button'
import { DInput } from '../../shared/d-input'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'
import { HomeHeader } from '../home/home-header'
import { Questions } from './questions'

export const NewQuiz = () => {

    const translations = services.translations.all()
    const base = Div()
    const header = PageTitle('Add a Quiz')
    const name = DInput(translations['NAME'])
    const questions = Questions()
    const submit = DButton(translations['CREATE'])
    const contents = PageContents()
    contents.append(name, questions, submit)
    base.append(header, contents)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    submit.el.onclick = handleSubmission

    async function handleSubmission() {
        await services.quizzes.create({
            title: name.getValue() || 'Untitled Quiz',
            questions: questions.getValue()
        })
        router.goto('/quizes')
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}