import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import images from '../../../configs/images'

export const AddQuestionButton = () => {

    const base = Div()
    const icon = Img(images.ICONS.ADD, {width: 32})
    const text = Div('Add a new question')
    base.append(icon, text)

    icon.cssClass({
        position: 'absolute',
        left: '-42px',
        borderRadius: '50%',
        border: '1px solid #000',
        opacity: '0.5',
        padding: '6px',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.75'
        },
        '&:active': {
            backgroundColor: '#ccc'
        }
    })
    text.cssClass({
        fontStyle: 'italic',
        color: '#979797'
    })
    base.cssClass({
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0',

    })

    return base
}