import { Div } from '../../../base/components/native/div'
import { IOption, IQuestion } from '../../../liumus-interfaces/quiz'
import { Option } from './option'

export const Options = (options: IOption[], isAdmin = true) => {

    const base = Div()
    let selectedOption: any = null
    options.forEach(o => {
        const option = Option(o)
        base.append(option)
        option.on('click', () => {
            if (selectedOption) selectedOption.unselect()
            selectedOption = option
            option.select()
        })
        if (!isAdmin) option.unselect()
        // if (o.isCorrect && isAdmin) selectedOption = option
    })
    base.cssClass({
        fontWeight: '100',
    })

    return base
}