import { ICourse } from '../liumus-interfaces/courses'
import { IQuiz } from '../liumus-interfaces/quiz'
import api from './api'

async function getOne(id: string) {
    return api.get<IQuiz>(`/api/quizzes/${id}`)
}

async function getByCourse(courseId: string) {
    return api.get<{items: IQuiz[]}>(`/api/quizzes/course/${courseId}`)
}

async function getAll() {
    return api.get('/api/quizzes')
}

async function create(data: Partial<IQuiz>) {
    return api.post('/api/quizzes', data)
}

async function update(id: string, data: Partial<IQuiz>) {   
    return api.put(`/api/quizzes/${id}`, data)
}

async function attempt(id: string, data: any) {
    return api.post(`/api/quizzes/${id}/attempt`, data)
}

async function getAttempts(quiz_id: string) {
    return api.get(`/api/quizzes/${quiz_id}/attempts`)
}

export default {
    getAll,
    create,
    getOne,
    update,
    getByCourse,
    attempt,
    getAttempts
}