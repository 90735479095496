import { Div } from '../../../../base/components/native/div'
import { IPerson } from '../../../../liumus-interfaces/people'

export const CourseAttempteeItem = (person: IPerson) => {

    const base = Div()
    const name = Div(person.name)
    const email = Div(person.email)
    base.append(name, email)

    base.cssClass({
        marginBottom: '15px',
        fontWeight: '100',
        cursor: 'pointer',
        padding: '5px 20px',
        borderRadius: '24px',
        width: 'fit-content',
        '&:hover': {
            opacity: '0.9'
        },
    })
    email.cssClass({
        opacity: '0.5',
        fontSize: '14px',
        lineHeight: '17px'
    })

    return Object.assign(base, {
        select: () => {
            base.style({
                // fontWeight: '500',
                // color: '#fff',
                backgroundColor: '#25252522',
            })
        },
        unselect: () => {
            base.style({
                fontWeight: '100',
                color: '#000',
                backgroundColor: 'transparent',
            })
        },
    })
}