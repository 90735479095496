import authUser from './firebase/auth-user'
const fetchPromises: any = {}

async function get<T>(url: string, params: any = {}): Promise<{ data: T, status: number, error?: Error | null }> {
    if (fetchPromises[url]) {
        return await fetchPromises[url]
    }
    const fetchPromise = (async () => {
        try {
            const token = await authUser.getToken()
            const options = {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }
            const response = await fetch(url, options)
            const data = await response.json()
            return { data, status: response.status }
        } catch (error) {
            console.log(error)
            return { error, status: error.status, data: null as any }
        } finally {
            fetchPromises[url] = null
        }
    })()

    fetchPromises[url] = fetchPromise 

    return await fetchPromise
}


export default {
    get,
    async post(url: string, params: any = {}) {
        try {
            const token = await authUser.getToken()

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(params),
            }
            const response = await fetch(/* prefix + */ url, options)
            const data = await response.json()
            return { data, status: response.status }
        } catch (error) {
            return { error, status: error.status }
        }
    },
    async put(url: string, params: any = {}) {
        try {
            const token = await authUser.getToken()

            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(params),
            }
            const response = await fetch(/* prefix + */ url, options)
            const data = await response.json()
            return { data, status: response.status }
        } catch (error) {
            return { error, status: error.status }
        }
    },
    async delete(url: string, params: any = {}) {
        try {
            const token = await authUser.getToken()

            const options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(params),
            }
            const response = await fetch(url, options)
            const data = await response.json()
            return { data, status: response.status }
        } catch (error) {
            return { error, status: error.status }
        }
    }
}