import { getAuth, signInWithPopup, GoogleAuthProvider, getIdToken, setPersistence, browserSessionPersistence, onAuthStateChanged } from "firebase/auth"
import emitter from '../../base/utils/emitter'
import authUser from './auth-user'

const provider = new GoogleAuthProvider()
// check firebase if user logged in / get token

// export async function checkLogin() {
//     const auth = getAuth()
//     setPersistence(auth, browserSessionPersistence)
//         .then(() => {
//             onAuthStateChanged(auth, (user) => {
//                 if (user) {
//                     // User is signed in, see docs for a list of available properties
//                     // https://firebase.google.com/docs/reference/js/firebase.User
//                     const uid = user.uid
//                     const email = user.email
//                     const photoURL = user.photoURL
//                     const displayName = user.displayName
//                     const emailVerified = user.emailVerified
//                     const providerId = user.providerId

//                     getIdToken(user).then((idToken) => {
//                         emitter.emit('logged-in', user)
//                     }).catch((error) => {
//                         // Handle error
//                     })
//                     // ...
//                 } else {
//                     // User is signed out
//                     // ...
//                 }
//             })
//         })
//         .catch((error) => {
//             // Handle Errors here.
//             const errorCode = error.code
//             const errorMessage = error.message
//             // ...
//         })
// }




export async function attemptLogin() {
    signInWithPopup(authUser.auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result)
            const token = credential?.accessToken
            const user = result.user
            emitter.emit('logged-in', user)
        }).catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            const email = error.customData.email
            const credential = GoogleAuthProvider.credentialFromError(error)
        })
}

export async function attemptLogout() {
    authUser.auth.signOut()
}