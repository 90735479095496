import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import device from '../../../base/helpers/device'
import services from '../../../services'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'
import { CourseTitles } from './course-titles'
import { ICourse } from '../../../liumus-interfaces/courses'
import { Loading } from '../../shared/loading'
import { AddButton } from '../../shared/add-button'
import ldb from '../../../base/lib/ldb'
import { A } from '../../../base/components/native/a'
import router, { IRouteParams } from '../../../base/lib/router'
import { HeaderLink } from '../../shared/header-link'

// User role can't create a course
export const Courses = () => {

    const translations = services.translations.all()
    const base = Div()
    const title = PageTitle(translations.COURSES as string)
    const contents = PageContents()
    base.append(title, contents,/*  !device.isMobile() && courseContent */)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    const homeLink = HeaderLink('/', 'Home / ')
    if (!device.isMobile()) title.prepend(homeLink)

    let courses: ICourse[] = []
    async function loadCoursesList() {
        contents.empty()
        contents.append(Loading())
        const { error, data, status } = await services.courses.getAll()
        if (status === 401) return router.goto('/login', { from: '/courses', redirect: true })
        courses = data?.items
        // if (status > 400) return router.goto('/login', { from: '/courses' })
        contents.empty()
        if (error) return contents.append(Div(error))
        const list = CourseTitles(courses)
        contents.append(list)
        const add = AddButton(device.isMobile() ? '+' : `+ ${translations['CREATE_A_NEW_COURSE']}`, '/courses/create')
        const roles = ldb.get('liumus-roles')// store.get('roles')
        if (roles === 'admin') {
            base.append(add)
        }
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to == '/') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }

    async function enter({ query, from, to, data, params }: IRouteParams) {
        document.title = 'Courses page'
        if (device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        await loadCoursesList()
        // if (!device.isMobile()) {
        //     const courseToShow = params.id// || courses[0]._id
        //     if (courseToShow) courseContent.load(courseToShow)
        // }
    }

    return Object.assign(base, { enter, exit })
}
