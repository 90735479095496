import { View } from './components/view'
import { HomePage } from './components/pages/home/home-page'
import { MenuPage } from './components/pages/menu/menu-page'
import { BackIcon } from './components/shared/back-icon'
import configs from './configs'
import { Courses } from './components/pages/courses/courses-page'
import { People } from './components/pages/people/people-page'
import { Setting } from './components/pages/setting/setting'
import { Analytics } from './components/pages/analytics/analytics'
import { LoginPage } from './components/pages/user/login'
import { NewPeople } from './components/pages/people/new-people'
import { CoursePage } from './components/pages/courses/course-page/course-page'
import { ReviewPage } from './components/pages/review/review-page'
import { NewQuiz } from './components/pages/quizzes/new-quiz'
import { QuizPage } from './components/pages/quizzes/quiz-page'
import { CreateCourse } from './components/pages/courses/create-course'
import { NotFound } from './components/pages/404'
import { QuizzesPage } from './components/pages/quizzes/quizzes-page'
import router from './base/lib/router'
import { Base } from './base/components/base'

export const App = () => {
    const base = Base()
    const backIcon = BackIcon()
    const view = View()
    base.append(backIcon, view)
    base.cssClass({
        margin: '0 auto',
        transition: 'all .16s',
        overflow: 'hidden',
        position: 'relative',
        [`@media (max-width: ${configs.sizes.MOBILE}px)`]: {
            margin: '0 auto',
        }
    })

    const routes = {
        '/': HomePage,
        '/menu/:id/test/:title': MenuPage,
        '/courses': Courses,
        '/courses/create': CreateCourse,
        '/courses/:id/*': CoursePage,
        '/quizzes': QuizzesPage, //device.isMobile() ? Course : Cours
        '/quizzes/:id': QuizPage, // todo: fix this
        '/new-quiz': NewQuiz, // todo: fix this
        '/people': People,
        '/new-people': NewPeople,
        '/review': ReviewPage,
        '/setting': Setting,
        '/analytics': Analytics,
        '/login': LoginPage,
        '/404': NotFound
    }

    router.init({ routes, view })//.goto('/')

    return base
}