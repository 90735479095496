import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import { Loading } from '../../shared/loading'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'
import { AddButton } from '../../shared/add-button'
import { Person } from './person'
import { IRouteParams } from '../../../base/lib/router'

export const People = () => {

    const base = Div()
    const header = PageTitle('People')
    const add = AddButton('+ Add People', '/new-people')
    const contents = PageContents()
    base.append(header, add, contents)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    async function loadPeople() {
        contents.append(Loading())
        try {
            const { data } = await services.people.all()
            contents.empty()
            data.items.forEach(person => {
                contents.append(Person(person))
            })
        } catch (error) {
            contents.empty()
            contents.append(Div('Error loading people'))
        }
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to == '/') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        loadPeople()
    }

    return Object.assign(base, { enter, exit })
}