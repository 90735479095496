import { Div } from '../../../base/components/native/div'
import { S } from '../../../base/helpers/style'
import store from '../../../base/services/store'
import { Scrollable } from '../../../base/utils/scrollable'
import helpers from '../../../helpers'
import { ICourse, IFlashcard, ICourseFlashcards, ICourseSlide } from '../../../liumus-interfaces/courses'
import models from '../../../models'
import services from '../../../services'
import { Loading } from '../../shared/loading'
import { CourseAt } from './contents/at'
import { CourseDescription } from './contents/description'
import { CourseFlashcards } from './contents/flashcards'
import { AddSlideOrFlashcard } from './contents/add-slide-or-flashcard'
import { CourseSlide } from './contents/slide'
import { CourseWelcome } from './course-welcome'
import auth from '../../../services/auth'
import authUser from '../../../services/firebase/auth-user'

export const CourseContent = () => {
    const base = Scrollable(Div())
    let __data = {} as ICourse

    async function load(data: ICourse) {

        __data = data
        base.empty()
        const description = CourseDescription(data.description)
        const at = CourseAt(data.at)
        const plus = AddSlideOrFlashcard()
        base.append(description)
        const uid = (await authUser.getUser())?.uid as string
        const roles = data.user_id === uid || data.admins?.includes(uid) ? 'admin' : 'user'
        if (roles === 'user') plus.style({ display: 'none' })
        plus.on('add-slide', handleAddSlide)
        plus.on('add-flashcard', handleAddFlashcard)

        for (const content of data.contents as any[]) {
            switch (content.type) {
                case 'flashcards':
                    // await Promise.all(content.items.map(async (flashcardId: string) => {
                    //     const fr = await services.flashcards.getOne(flashcardId)
                    //     return fr
                    // })).then((cards: { data: IFlashcard }[]) => {
                        const flashcards = CourseFlashcards({ type: 'flashcards', items: content.flashcardData }, { roles, courseId: data._id })
                        base.append(flashcards)
                    // })
                    break
                case 'slide':
                    if (!content._id) break
                    // const sr = await services.slides.getOne(content._id)
                    const slide = CourseSlide(content.slideData as ICourseSlide, { roles })
                    base.append(slide)
                    break
            }
        }
        base.append(plus)
        base.el.scrollTop = 0

        async function handleAddSlide() {
            const slideContents = models.slide.create()
            const response = await services.slides.create(slideContents)
            const slide = CourseSlide(response.data, { roles })
            base.appendBefore(plus, slide)
        }

        async function handleAddFlashcard() {
            const flashcardsContent = models.flashcard.create()
            const response = await services.flashcards.create(flashcardsContent)
            const flashcards = CourseFlashcards({ type: 'flashcards', items: [response.data] }, { roles })
            base.appendBefore(plus, flashcards)
        }
    }
    let w = window.innerWidth - 200
    let p = Math.max(w / 2 - 512, 20)
    base.cssClass({
        textAlign: 'center',
        position: 'absolute',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        zIndex: '99',
        padding: helpers.device.isMobile() ? '22px 00px 120px 00px' : `22px ${p}px 120px`,
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: 'calc(100vh - 62px)',

    })

    return Object.assign(base, {
        load,
        getData() {
            const newData = (base.getChildren() as any[]).filter(item => item?.getData).map(i => i.getData())
            __data.contents = newData
            return __data
        },
        async update() {
            const children = base.getChildren() as any[]
            const toUpdate = children.filter(i => i?.update)
            await Promise.all(toUpdate.map(i => i.update()))
        }
    })
}