import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import router from '../../../../base/lib/router'
import { FeatureDescription } from './feature-description'
import { FeatureTitle } from './feature-title'

export const Feature = (text: string = '[title]', icon: string, target: string, desc: string,  options: any = {}) => {

    const base = Div()
    const title = FeatureTitle(text)
    const image = Img(icon, { width: 80 })
    const description = FeatureDescription(desc)
    const rest = Div()
    rest.append(title, description)
    base.append(image, rest)
    base.el.onclick = () => router.goto(target)

    rest.cssClass({
        marginLeft: '20px',
    })

    base.cssClass({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',   
        width: '340px',
        height: '150px',
        margin: '20px',
        cursor: 'pointer',
        fontSize: '24px',
        fontWeight: '100',
        borderRadius: '20px',
        padding: '20px',
        // border: '1px solid #ccc',
        boxShadow: 'rgb(0 0 0 / 5%) 7px 2px 14px;',
        opacity: options.disabled ? '.4' : '1',
        pointerEvents: options.disabled ? 'none' : 'auto',
        '&:hover': {
            opacity: '.8'
        },
    })


    return Object.assign(base, {
        show() {
            base.style({ display: 'flex' })
        },
        hide() {
            base.style({ display: 'none' })
        }
    })
}