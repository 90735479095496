import { Base } from '../../base/components/base'
import { Scrollable } from '../../base/utils/scrollable'

export const PageContents = (options: any = {}) => {
    options = { adjustedHeight: 0, padding: [20, 60, 100], ...options }
    const base = Scrollable(Base())

    base.cssClass({
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: options.padding.map((p: number) => p + 'px').join(' '),
        top: '0',
        right: '0',
        backgroundColor: 'white',
        height: (window.innerHeight - options.adjustedHeight) + 'px',
        scrollBehavior: 'smooth',
        // display: 'flex',
        flexDirection: 'column',
        zIndex: '1',
        margin: '0 auto',
    })

    return base
}
