import { Div } from '../../../../base/components/native/div'
import { CENTER, ROUND, WH } from '../../../../base/helpers/style'

export const SimplePlus = () => {

    const base = Div('+')
    base.cssClass({
        backgroundColor: '#dbdbdb',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '28px',
        ...WH(36),
        ...CENTER,
        ...ROUND,
        '&:hover': {
            opacity: 0.8,
        }
    })

    return base
}