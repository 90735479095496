import { IBaseComponent } from '../../../../base/components/base'
import { Div } from '../../../../base/components/native/div'
import { Y, X } from '../../../../base/helpers/style'
import { IRouteParams } from '../../../../base/lib/router'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import { Loading } from '../../../shared/loading'
import { Questions } from '../../quizzes/questions'
import { ActionButton, IActionButton } from './action-button'
import { SubCourseButtons } from './sub-course-buttons'
import { SubPageContents } from './sub-page-contents'

export const CourseQuiz = () => {

    const base      = Div()
    const save      = ActionButton('Save quiz', handleSave, {color: '#2772ff'})
    const publish   = ActionButton('Publish quiz', handlePublish)
    const unpublish = ActionButton('Unpublish quiz', handleUnpublish, {color: '#606060'})
    const attempt   = ActionButton('Submit your answers!', handleAttempt)
    const contents  = SubPageContents()
    const buttons   = SubCourseButtons()
    buttons.append(save, publish, unpublish, attempt)
    base.append(contents, buttons)

    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        ...Y(0),
        ...X(window.innerWidth)
    })

    let questionsComponents: IBaseComponent<any> & { getValue: () => any }
    let quizRawData: any = {}
    let course_id: string

    async function load(courseId: string, isAdmin: boolean) {
        contents.append(Loading())
        const raw = await services.quizzes.getByCourse(courseId)
        contents.empty()
        contents.el.scrollTop = 0
        quizRawData = raw.data.items?.[0] || {}
        questionsComponents = Questions(quizRawData.questions, isAdmin)
        contents.append(questionsComponents)
    }

    function exit() {
        base.style(helpers.styles.PAGE_EXIT_RIGHT_STYLE);
        [save, publish, unpublish, attempt].forEach((b: IActionButton) => b.hide())
        setTimeout(() => {
            
            contents.empty()
        }, 200);
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        const { roles, course } = data
        course_id = course._id
        const isAdmin = roles.includes('admin')
        await load(data.course._id, isAdmin)
        if (isAdmin) save.show()
        if (isAdmin /* && !quizRawData.isPublished */) publish.show()
        if (isAdmin && quizRawData.isPublished) unpublish.show() 
        if (!isAdmin && quizRawData.isPublished) attempt.show()
        publish.show()
        attempt.show()
    }

    async function handleSave() {
        await services.quizzes.update(quizRawData._id, { questions: questionsComponents.getValue() })
    }

    async function handlePublish() {
        await services.quizzes.update(quizRawData._id, { isPublished: true })
        publish.style({ display: 'none' })
        unpublish.style({ display: 'block' })
    }

    function handleUnpublish() {
        services.quizzes.update(quizRawData._id, { isPublished: false })
        publish.style({ display: 'block' })
        unpublish.style({ display: 'none' })
    }

    async function handleAttempt() {
        // todo: validate all have answers
        // todo: validate all have score
        if (questionsComponents.getValue().some((q: any) => !q.options.some((o: any) => o.isCorrect))) {
            alert('Please answer all questions')
            return
        }
        await services.quizzes.attempt(quizRawData._id, { course_id, answers: questionsComponents.getValue() })
    }

    return Object.assign(base, { enter, exit })
}