import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import { CENTER, WH, EASE, Y, SHOW, HIDE } from '../../../base/helpers/style'
import images from '../../../configs/images'
import { MenuIcon } from '../../shared/menu-icon/menu-icon'
import { PageTitle } from '../../shared/page-title'


export const HomeHeader = () => {

    const base = PageTitle('Home')

    const menuIcon = MenuIcon()
    const logo = Img(images.RES.LOGO, { width: 86 })
    // const shadow = Div()

    base.append(menuIcon/* , logo */)

    logo.cssClass({
        fontSize: '22px',
        color: '#333',
        display: 'flex',
       
    })


    return Object.assign(
        base,
        {
            up() {
                base.style({ transform: 'translateY(calc(-76px - env(safe-area-inset-top)))' })
            },
            down() {
                base.style({
                    ...Y(0)
                })
            }
        }
    )
}