import { Div } from '../../../base/components/native/div'

export const Welcome = () => {

    const contenst = `<h1 style="text-align:center; font-weight:100">How it works?</h1>
    At Liumus, we redefine how corporations train and onboard their employees. Our innovative platform offers micro-courses tailored for rapid and efficient learning, perfect for bringing new team members up to speed and keeping your existing workforce knowledgeable and competent.
    <ul>
    <li style="margin:20px 0 20px"><b>Effortless Course Creation:</b> Transform raw content into comprehensive courses with just one click! Our advanced AI technology automatically crafts interactive slides, engaging quizzes, and effective flashcards, making course development a breeze.</li>
    <li style="margin:20px 0 20px"><b>Seamless Integration:</b> Liumus integrates smoothly with your existing systems, ensuring a unified and hassle-free experience for both administrators and learners.</li>
    <li style="margin:20px 0 20px"><b>Real-Time Analytics:</b> Stay on top of your team's progress with our robust analytics tools. Track learning outcomes, engagement levels, and more to ensure everyone is on the right track.</li>
    <li style="margin:20px 0 20px"><b>Customizable Learning Paths:</b> Tailor learning experiences to meet the unique needs of different roles within your organization. Our platform allows for the creation of specialized paths, ensuring relevant and focused learning.</li>
    <li style="margin:20px 0 20px"><b>Interactive Learning Community:</b> Encourage knowledge sharing and collaboration with our interactive community features. Employees can engage, discuss, and learn from each other, enriching the learning experience.</li>
    <li style="margin:20px 0 20px"><b>Mobile Learning:</b> With our mobile-friendly platform, learning doesn’t have to stop at the office door. Enable your team to learn on-the-go, anytime, anywhere.</li>
    <li style="margin:20px 0 20px"><b>Secure and Reliable:</b> We prioritize the security and privacy of your data, offering a reliable and safe learning environment.</li>
    </ul>
    Join Liumus today and empower your workforce with the knowledge they need to excel. Learn smarter, grow faster with Liumus – where learning meets innovation.`
    
    const base = Div(contenst)
    base.cssClass({
        margin: '50px 100px',
        color: 'gray',
        fontWeight: '100',
        '@media (max-width: 768px)': {
            margin: '50px 20px',
        }
    })

    return base
}