import { Div } from '../../../../base/components/native/div'

export const CourseDescription = (description: string) => {

    const base = Div(description)
    // base.el.setAttribute('contenteditable', 'true')
    base.cssClass({
        fontSize: '18px',
        color: '#333',
        margin: '10px auto',
        maxWidth: '330px'
    })

    return base
}