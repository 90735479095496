import { ICoursePayload, ICourseSlide, ISlidePayload } from '../liumus-interfaces/courses'
import api from './api'

async function create(data: ISlidePayload) {
    return api.post('/api/slides', data)
}

async function getAll() {
    return api.get('/api/slides')
}

async function getOne(id: string) {
    // Todo: get form the cache if we have it and if the TTL is not expired
    // if not, get from the server
    return api.get(`/api/slides/${id}`)
}

async function update(data: ICourseSlide) {
    return api.put(`/api/slides/${data._id}`, data)
}

export default {
    create,
    getAll,
    getOne,
    update
}