import { Div } from '../../../../base/components/native/div'
import { IQuestion, IAttempt, IAnswer } from '../../../../liumus-interfaces/quiz'
import { AttemptQuestion } from './course-attempt-question'

export const AttemptsOverview = (questions: IQuestion[], attempts: IAttempt[]) => {

    const base = Div()
    const sum = attempts.reduce((a, b) => a + b.score, 0)
    const med = sum / attempts.length
    const score = Div('Average Score: ' + med.toFixed(2))
    base.append(score)

    questions.forEach((q, i) => {
        const answers = attempts.map(attempt => attempt.answers.find(a => a.id === q.id)) as IAnswer[]
        console.log(answers, 'answers');
        
        const question = AttemptQuestion(q, answers.map(a => a.userAnswerIndex), i)
        base.append(question)
    })

    score.cssClass({
        fontSize: '24px',
        lineHeight: '10px',
        marginBottom: '30px',
        fontWeight: '100'
    })

    return base
}