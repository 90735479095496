import { Editable } from '../../../base/components/advanced/input/editable'
import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import device from '../../../base/helpers/device'
import { CENTER, EASE, HIDE, SHOW } from '../../../base/helpers/style'
import router, { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import images from '../../../configs/images'
import helpers from '../../../helpers'
import services from '../../../services'
import { DButton } from '../../shared/d-button'
import { Loading } from '../../shared/loading'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'

export const CreateCourse = () => {

    const translations = services.translations.all()
    const base = Div()
    const header = PageTitle(translations['CREATE_A_NEW_COURSE'])
    const raw = Editable({ placeholder: 'Insert the raw contents of the course here.', removeFormattingOnPaste: true })
    const contents = PageContents()
    const submit = DButton('Generate course slides, flashcards, and quizzes using AI')
    const magic = Img(images.ICONS.MAGIC, { width: 22 })
    const manual = Div('Or create it manually')
    submit.el.onclick = handleSubmission
    submit.prepend(magic)
    contents.append(raw, submit, manual)
    base.append(header, contents)
    contents.style({ paddingTop: '20px', paddingRight: '60px' })
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    raw.cssClass({
        border: '1px solid #ccc',
        minHeight: 'calc(100vh - 240px)',
        marginBottom: '20px',
        padding: '50px',
        borderRadius: '20px',
        fontWeight: '100',
        width: '100%',
    })
    manual.cssClass({
        color: 'gray',
        fontSize: '16px',
        cursor: 'pointer',
    })
    contents.cssClass({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    })
    submit.cssClass({
        flexShrink: '0',
        marginBottom: '20px',
        width: '500px',
        padding: '0 20px',
        minWidth: '100px',
        height: '56px',
        borderRadius: '28px',
        background: 'linear-gradient(90deg, rgb(0 164 117) 0%, rgb(124 0 255) 100%)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        ...EASE(.16),
        ...CENTER,
        '&:active': {
            boxShadow: 'none',
            transform: 'scale(.98)',
        },
    })
    magic.cssClass({
        marginRight: '5px',
        opacity: '.8',
        marginTop: '-7px',
    })
    async function handleSubmission() {
        submit.disable()
        submit.style({ width: '100px', color:'transparent', ...EASE(.30, 'width', 'ease-in-out') }, 150)
        magic.style(HIDE)
        const loading = Loading()
        setTimeout(() => {
            submit.append(loading)
        }, 500)


        const response = await services.ai.createFullCourse(raw.getText())
        console.log('response', response.data)
        router.goto('/courses/' + response.data.course._id )
        submit.enable()
        submit.style({ width: '500px', color: 'white', ...EASE(.30, 'width', 'ease-in-out') }, 150)
        magic.style(SHOW)
        loading.remove()
        raw.setValue('')
        
        // submit.enable()
        // raw.setValue('')
        // router.back()
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to?.includes('/courses')) base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }


    async function enter({ query, from, to, data }: IRouteParams) {
        if (device.isMobile()) await waitFor(200)

        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}