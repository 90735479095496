import { Base } from "../../../base/components/base"
import { Img } from "../../../base/components/native/img"
import { Span } from "../../../base/components/native/span"
import { CENTER } from "../../../base/helpers/style"
import configs from '../../../configs'
import services from '../../../services'

export const GoogleButton = () => {

    const translations = services.translations.all()
    const base = Base()
    const google = Img(configs.images.ICONS.GOOGLE, { width: 24 })
    const txt = Span(translations['LOGIN_WITH_GOOGLE'])
    txt.cssClass({
        marginLeft: '10px',
    })

    base.append(google, txt)
    base.cssClass({
        ...CENTER,
        border: '1px solid #eee',
        borderRadius: '10px',
        padding: '10px',
        width: '100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#fefefe',
        }
    })

    base.el.onclick = async () => {
        txt.text(translations['PLEASE_WAIT'])
        base.emit('request-login')
    }

    return base
}