import { Editable } from '../../../base/components/advanced/input/editable'
import { Div } from '../../../base/components/native/div'
import { Span } from '../../../base/components/native/span'
import { IQuestion } from '../../../liumus-interfaces/quiz'
import { Option } from './option'
import { Options } from './options'
import { RemoveQuestionButton } from './remove-quesiton-button'

export const Question = (q: IQuestion, i = 1, isAdmin = true) => {

    const base = Div()
    const remove = RemoveQuestionButton()
    const index = Span(i + '.')
    const title = Editable({ text: q.title, selectOnClick: true, removeFormattingOnPaste: true })
    const options = Options(q.options, isAdmin)
    if (isAdmin) base.append(remove)
    base.append(index, title, options)

    title.on('blur', (text: string) => {
        if (text === '') return title.el.innerHTML = q.title
        console.log('option title changed', text)
        q.title = text
    })


    remove.el.onclick = () => {
        base.emit('remove')
        // base.remove()
    }
    index.cssClass({
        fontSize: '36px',
        lineHeight: '16px',
        margin: '0 10px',
        color: '#ccc',
        fontWeight: 'bold',
        display: 'inline-block',
    })
    title.cssClass({
        fontWeight: '500',
        margin: '16px',
        top: '0',
        // left: '25px',
        fontSize: '18px',
    })
    base.cssClass({
        marginBottom: '60px',
        position: 'relative'
    })


    return Object.assign(base )
}