import { IBaseComponent } from '../base/components/base'
import { Div } from '../base/components/native/div'
import { Img } from '../base/components/native/img'
import { WH } from '../base/helpers/style'
import images from '../configs/images'
import services from '../services'
import { IEditorOptions } from './interfaces/editor'
import { LinkBox } from './link-box'
// import Trix from 'trix'
export const Editor = (components: IBaseComponent<any>[]) => {

    const base = Div()
    const b = Div('B')
    const i = Div('I')
    const u = Div('U')
    const a = Div('A')
    const m = Img(images.ICONS.IMAGE, { width: 21 })

    // var inputElement = document.createElement("input");
    // inputElement.setAttribute("type", "hidden");
    // inputElement.setAttribute("id", "x");
    // base.el.appendChild(inputElement);

    // // Create the Trix editor element
    // var trixElement = document.createElement("trix-editor");
    // trixElement.setAttribute("input", "x");
    // base.el.appendChild(trixElement);

    base.append(b, i, u, a, m)
    // const editor = new Squire(base.el)
    b.cssClass({ margin: '0 15px', ...WH(25), opacity: '.4', cursor: 'pointer' })
    i.cssClass({ margin: '0 15px', ...WH(25), opacity: '.4', fontStyle: 'italic', cursor: 'pointer' })
    u.cssClass({ margin: '0 15px', ...WH(25), opacity: '.4', cursor: 'pointer' })
    a.cssClass({ margin: '0 15px', ...WH(25), opacity: '.4', cursor: 'pointer' })
    m.cssClass({ margin: '0 15px', ...WH(25), opacity: '.4', cursor: 'pointer', marginTop: '-3px' })
    components.forEach(c => {
        c.el.setAttribute('contenteditable', 'true')
    })
    b.el.onmousedown = (e) => { e.preventDefault(); applyStyle('b', components) }
    i.el.onmousedown = (e) => { e.preventDefault(); applyStyle('i', components) }
    u.el.onmousedown = (e) => { e.preventDefault(); applyStyle('u', components) }
    a.el.onmousedown = (e) => {
        e.preventDefault()
        const selection = window.getSelection()
        if (!selection?.toString()) return
        const range = selection.getRangeAt(0).cloneRange()
        const selectedText = range.toString()
        const modal = LinkBox()
        a.parent.append(modal)

        modal.on('confirm', (url: string) => {
            range.deleteContents()
            const linkElement = document.createElement('a')
            linkElement.href = url
            linkElement.textContent = selectedText
            linkElement.target = '_blank'
            linkElement.onclick =  function(e) {
                e.preventDefault();
                console.log(e);
                
                if (!e.ctrlKey) {
                }
            };
            range.insertNode(linkElement)
            modal.remove()
        })
        modal.on('cancel', () => {
            modal.remove()
        })
    }
    m.el.onclick = () => handleUploadMedia(components[0])
    components[0].el.addEventListener('mouseup', saveSelection)
    components[0].el.addEventListener('keyup', saveSelection)
    base.cssClass({
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
    })

    return base
}

function applyStyle(tag: string, components: IBaseComponent<any>[]) {
    const selection = window.getSelection()
    if (!selection?.rangeCount) return
    const range = selection.getRangeAt(0)
    const ancestor = range.commonAncestorContainer
    const activeElement = findActiveElement(ancestor, tag)

    if (activeElement) {
        const content = document.createDocumentFragment()
        while (activeElement.firstChild) {
            content.appendChild(activeElement.firstChild)
        }
        activeElement.parentNode?.replaceChild(content, activeElement)
    } else {
        if (!range.collapsed && containsOnlyOneTag(range.cloneContents(), tag)) return
        if (range.collapsed) {
            const newElement = document.createElement(tag)
            if (!newElement.textContent?.trim()) {
                newElement.textContent = '\u200B' // Insert zero-width space
            }
            range.insertNode(newElement)
            range.setStart(newElement, 0)
            range.setEnd(newElement, 1)
            selection.removeAllRanges()
            selection.addRange(range)
        } else {
            const content = range.extractContents()
            const wrapper = document.createElement(tag)
            wrapper.appendChild(content)
            range.insertNode(wrapper)
            selection.removeAllRanges()
        }
    }
}

function containsOnlyOneTag(fragment: DocumentFragment, tag: string) {
    const elements = fragment.querySelectorAll(tag)
    return elements.length === 1 && elements[0].contains(fragment.firstChild) && elements[0].contains(fragment.lastChild)
}

function findActiveElement(ancestor: Node, tag: string) {
    while (ancestor && ancestor.nodeName !== tag.toUpperCase() && ancestor.nodeName !== 'DIV') {
        ancestor = ancestor.parentNode as Node
    }
    return ancestor?.nodeName !== 'DIV' ? ancestor : null
}

async function handleUploadMedia(c: IBaseComponent<any>) {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = () => {
        if (!input.files) return
        const file = input.files[0]
        uploadMedia(file, c)
    }
    input.click()
}

async function uploadMedia(file: File, c: IBaseComponent<any>) {
    const { signature, timestamp } = await services.cloudinary.getSignature(file.name)
    const fileType = file.type.split('/')[0] // Get the file type (either "image" or "video")
    const url = `https://api.cloudinary.com/v1_1/dravumsss/${fileType}/upload` // Set the upload URL based on file type
    const formData = new FormData()
    formData.append('file', file)
    formData.append('timestamp', timestamp)
    formData.append('signature', signature)
    formData.append('api_key', '767231811454994')
    formData.append('public_id', 'liumus/temp/' + timestamp + '-' + file.name)
    var xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)
    // create a placeholder for the media in the editable div
    const editableDiv = document.createElement('div')
    const mediaPlaceholder = document.createElement(fileType === 'image' ? 'img' : 'video')
    mediaPlaceholder.style.opacity = '0.5'
    mediaPlaceholder.style.maxWidth = '100%'
    // mediaPlaceholder.style.margin = '0 auto'
    // mediaPlaceholder.style.display = 'block'
    const progressDiv = document.createElement('div')
    progressDiv.textContent = '0%'
    progressDiv.style.margin = '0 auto'
    progressDiv.style.display = 'block'
    restoreSelection()
    // insert placeholders into editableDiv
    editableDiv?.appendChild(mediaPlaceholder)
    editableDiv?.appendChild(progressDiv)
    // c.el.appendChild(editableDiv as HTMLElement)
    insertAtCaret(c.el, editableDiv as HTMLElement)
    // read file and show a preview
    const reader = new FileReader()
    reader.onload = function (e) {
        mediaPlaceholder.src = e.target?.result as string
    }
    reader.readAsDataURL(file)
    xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
            var percentComplete = (e.loaded / e.total) * 100
            console.log(percentComplete + '% uploaded')
            progressDiv.textContent = `${Math.round(percentComplete)}%`
        }
    }
    xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
            var data = JSON.parse(this.response)
            if (fileType === 'video') {
                handleSubmit(`<video style="width:100%" src=${data.secure_url}>`)
            } else if (fileType === 'image') {
                handleSubmit(`<img style="width:100%" src=${data.secure_url}>`)
            }
            // handleSubmit(`<video style="width:100%" src=${data.secure_url}>`)
            // update the media placeholder's src and style
            mediaPlaceholder.src = data.secure_url
            mediaPlaceholder.style.opacity = '1'
            // remove the progressDiv
            progressDiv.remove()
            console.log({ data })
        } else {
            console.error(this.statusText)
        }
    }
    xhr.onerror = function () {
        console.error(this.statusText)
    }
    xhr.send(formData)
}

async function handleSubmit(body: string) {
    // appendText({ body } as IText)
    // contents.el.scrollTop = contents.el.scrollHeight
    // const content = await services.contents.create({ body }) as IText
    // const post = await services.posts.create(content?._id, channel_id, 'text')
}

function insertAtCaret(el: HTMLElement, node: Node) {
    let range, sel

    if (window.getSelection) {
        sel = window.getSelection()
        if (!sel) return
        if (sel.rangeCount > 0) {
            range = sel.getRangeAt(0)
            range.collapse(false)
            range.insertNode(node)
        } else {
            // If no selection, append node to the end
            el.appendChild(node)
        }
    }
}

let savedRange: Range | null = null

function saveSelection() {
    const sel = window.getSelection()
    if (!sel) return
    if (sel.getRangeAt && sel.rangeCount) {
        savedRange = sel.getRangeAt(0)
    }
}

function restoreSelection() {
    const sel = window.getSelection()
    if (!sel) return

    if (savedRange) {
        sel.removeAllRanges()
        sel.addRange(savedRange)
    }
}