import { Div } from '../../../base/components/native/div'
import { waitFor } from '../../../base/utils/wait'
import { HomeHeader } from './home-header'
import { PageContents } from '../../shared/page-contents'
import helpers from '../../../helpers'
import { Features } from './features/features'
import device from '../../../base/helpers/device'
import { Logo } from './logo'
import { Welcome } from './welcome'

export const HomePage = () => {

    const base      = Div()
    const header    = HomeHeader()
    const contents  = PageContents({ padding: [0, 0, 100]})
    const logo      = Logo()
    const features  = Features()
    const welcome   = Welcome()
    contents.append(logo, features, welcome)
    base.append(header, contents)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    contents.on('scroll', header.toggleShadow)
    
    function exit() {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter() {
        if (device.isMobile()) await waitFor(200)
        features.update()
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}