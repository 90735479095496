import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import { HIDE, SHOW, WH, Y } from '../../../../base/helpers/style'
import images from '../../../../configs/images'
import helpers from '../../../../helpers'
import { ICourseFlashcards, ICourseSlide } from '../../../../liumus-interfaces/courses'
import models from '../../../../models'
import services from '../../../../services'
import { ColorsIcon } from './colors-icon'
import { CourseFlashcards } from './flashcards'
import { AddSlideOrFlashcard } from './add-slide-or-flashcard'
import { Toolbar } from './toolbar'
import { GenerateFlashcards } from './generate-flashcards'
import { Loading } from '../../../shared/loading'

export const CourseSlide = (slide: ICourseSlide, options: any = {}) => {

    const base = Div()
    const plus = AddSlideOrFlashcard()
    const generate = GenerateFlashcards()
    const body = slide.content//.replace(/<img/g, '<img style="max-width: 100%"')
    const pure = helpers.html.cleanPaste(body).replace(/<img/g, '<img style="max-width: 100%"')
    const content = Div(pure)
    const toolbar = Toolbar([content])
    const remove = Img(images.ICONS.DELETE, { width: 24 })
    const colors = ColorsIcon()
    const buttons = Div()
    buttons.append( remove)
    base.append(plus, toolbar, content, buttons)

    remove.style(HIDE)
    toolbar.style(HIDE)
    base.el.addEventListener('mouseover', () => {
        if (options.roles === 'user') return
        remove.style(SHOW)
        toolbar.style(SHOW)
    })
    base.el.addEventListener('mouseleave', () => {
        remove.style(HIDE)
        toolbar.style(HIDE)
    })

    generate.el.onclick = handleGenerate
    async function handleGenerate() {
        generate.empty()
        generate.append(Loading())
        generate.el.onclick = null
        console.log(slide.content)
        const raw = await services.ai.generateFlashcards(slide.content)
        const flashcards = JSON.parse(raw?.data || '[]')
        console.log('received', flashcards);
        
        const flashcardContent = models.flashcard.create(flashcards.front, flashcards.back)
        console.log(flashcardContent);
        
        const r = await services.flashcards.create(flashcardContent)
        const newSlide = CourseFlashcards({ type: 'flashcards', items: [r.data] }, options)
        base.parent.appendAfter(base, newSlide)
        generate.empty()
        generate.el.onclick = handleGenerate
    }
    if (options.roles === 'user') {
        setTimeout(() => {
            content.el.setAttribute('contenteditable', 'false')
        }, 100)
    }
    content.el.onclick = () => {
        if (content.el.innerHTML.length < 20) {
            helpers.html.selectAll(content.el)
        }
    }
    content.el.addEventListener('paste', function (e) {
        e.preventDefault()
        let clipboardData = e.clipboardData
        let pastedData = clipboardData?.getData('text/html') || ''
        const html = helpers.html.cleanPaste(pastedData)
        helpers.html.handlePaste(html)
    })
    remove.el.onclick = base.remove

    plus.on('add-slide', async () => {
        const slideContents = models.slide.create()
        const response = await services.slides.create(slideContents)
        const newSlide = CourseSlide(response.data)
        base.parent.appendBefore(base, newSlide)
    })
    plus.on('add-flashcard', async () => {
        const flashcardContent = models.flashcard.create()
        const response = await services.flashcards.create(flashcardContent)
        const newSlide = CourseFlashcards({ type: 'flashcards', items: [response.data] }, options)
        base.parent.appendBefore(base, newSlide)
    })
    const W = Math.min(window.innerWidth - 40, 900)

    if (options.roles === 'user') {
        plus.style({ display: 'none' })
        toolbar.style({ display: 'none' })
        remove.style({ display: 'none' })
    }

    generate.cssClass({
        position: 'absolute',
        bottom: '8px'
    })
    remove.cssClass({
        cursor: 'pointer',
        // ...HIDE,
    })
    content.cssClass({
        borderRadius: '1.5rem',
        backgroundColor: 'white',
        width: W + 'px',
        minHeight: W * 3 / 5 + 'px',
        // boxShadow: '0 0 10px 0 rgba(0,0,0,.1)',
        position: 'relative',
        padding: '40px 80px',
        marginTop: '10px',
        // border: '1px solid #ccc',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 1.5rem'

        // backgroundColor: slide.background || 'white',
        // overflow: 'hidden',
    })
    base.cssClass({
        // backgroundColor: ' rgba(0, 0, 0, 0.08)',
        borderRadius: '20px',
        position: 'relative',
        fontWeight: '100',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        // maxHeight: W + 'px',
        textAlign: 'center',
        maxWidth: '1200px',
        margin: '100px auto 0',
        paddingBottom: '50px',
        paddingTop: '10px'
    })
    plus.style({
        position: 'absolute',
        top: '-100px',
    })

    buttons.cssClass({
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // display: 'grid',
       alignItems: 'center',
        // gridTemplateColumns: '1fr 1fr 1fr',
    })
    return Object.assign(base, {
        getValue: () => {
            slide.content = content.el.innerHTML //?
            slide.last_change = new Date().toISOString()
            // slide.lastChangedBy = user.id
            return slide
        },
        async update() {
            var style = window.getComputedStyle(content.el)
            slide.content = /* quill.getContents() // */ content.el.innerHTML
            slide.background = style.getPropertyValue("background-color")
            slide.last_change = new Date().toISOString()
            await services.slides.update(slide)
        },
        getData: () => ({ _id: slide._id, type: 'slide' })
    })
}

