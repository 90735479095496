import { Div } from '../../../base/components/native/div'
import ldb from '../../../base/lib/ldb'
import { IRouteParams } from '../../../base/lib/router'
import state from '../../../base/services/state'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import liumusStrings from '../../../liumus-strings'
import services from '../../../services'
import { PageContents } from '../../shared/page-contents'
import { HomeHeader } from '../home/home-header'

export const Setting = () => {

    const translations = services.translations.all()
    const [ getLocale, setLocale] = state.use('locale')
    const base = Div()
    const contents = PageContents()
    base.append( contents)
    const title = Div(translations['CHANGE_LANGUAGE'])
    const en = Div('English')
    const uk = Div('Українська')
    contents.append(title, en, uk)

    en.el.onclick = () => {
        setLocale('en')
        ldb.set('liumus-locale', 'en')
        location.reload()
    }
    uk.el.onclick = () => {
        setLocale('uk')
        ldb.set('liumus-locale', 'uk')
        location.reload()
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to == '/') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
    if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }
    title.cssClass({ fontSize: '20px', margin: '0px 0 30px' })
    en.cssClass({ cursor: 'pointer', margin: '10px 0', opacity: getLocale() == 'en' ? '1' : '.5' })
    uk.cssClass({ cursor: 'pointer', margin: '10px 0', opacity: getLocale() == 'uk' ? '1' : '.5' })
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    return Object.assign(base, { enter, exit })
}
