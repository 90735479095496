import { Img } from '../../../../base/components/native/img'
import { Input } from '../../../../base/components/native/input'
import { ROUND, WH } from '../../../../base/helpers/style'
import images from '../../../../configs/images'

export const ColorsIcon = () => {

    const base = Img(images.ICONS.COLORS, { width: 24 })
    const colorInput = Input('', 'color')
    base.el.appendChild(colorInput.el)
    colorInput.el.style.display = 'none'
    colorInput.el.style.position = 'absolute'
    colorInput.el.style.top = '0'
    colorInput.el.style.right = '0' 
    base.el.onclick = () => {
        colorInput.el.click()
    }
    colorInput.el.onchange = () => {
        base.emit('change', colorInput.el.value)
    }

    base.cssClass({
        ...ROUND,
        ...WH(24),
        position: 'absolute',
        right: '-36px',
        cursor: 'pointer',
    })

    return base
}