import ldb from '../base/lib/ldb'
import { IFlashcard } from '../liumus-interfaces/courses'
import services from '../services'
const translations = services.translations.all()
function create(front = 'Click to edit', back = 'Click to edit'): IFlashcard {
    console.log('in create', front, back);
    
    const now = new Date().toISOString()
    return {
        at: now,
        last_change: now,
        // type: 'flashcard',
        // usserId: ldb.get('liumus-user')?.profile?.email, // Todo: change to id and service
        // _id: '',
        q: front,
        a: back,
    }
}

export default {
    create
}