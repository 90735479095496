import { Div } from '../../../../base/components/native/div'
import { Y, X } from '../../../../base/helpers/style'
import { IRouteParams } from '../../../../base/lib/router'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import { AddCourseLearners } from './add-course-learners-form'
import { CourseLearnersList } from './course-learners-list'
import { CourseLearnersListItem } from './course-learners-list-item'
import { SubPageContents } from './sub-page-contents'

export const CourseLearners = () => {

    const base = SubPageContents()

    const add = AddCourseLearners()
    const list = CourseLearnersList()
    base.append(add, list)
    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        ...Y(0),
        ...X(window.innerWidth),
        padding: '52px',
    })

    add.on('subscribed', (person: any) => {
        list.prepend(CourseLearnersListItem(person))
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        list.empty()
    }

    async function enter({ params, from, data }: IRouteParams) {
        console.log('ENTER COURSE LEARNERS', params, data, from)

        if (!data?.course) {
            const remote = await services.courses.getOne(params.id) as any
            console.log('REMOTE DATA', remote.data)

            data = { course: remote.data }
        }
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        list.wait()
        const subscribers = await services.courses.getSubscribers(data.course._id) as any
        list.fill(subscribers.data.items)
        add.setCourseId(params.id)
    }

    return Object.assign(base, { enter, exit })
}