import { Button } from '../../../../base/components/native/button'
import { Div } from '../../../../base/components/native/div'
import { DButton } from '../../../shared/d-button'
import { DInput } from '../../../shared/d-input'

export const AddCourseLearnersNewUser = () => {

    const base = Div()
    const title = Div('User not found, do you want to send an invitation?')
    const name = DInput('Name')
    const email = DInput('Email', 'email')
    const submit = DButton('Send invitation')

    base.append(title, name, email, submit)

    submit.el.onclick = () => {
        name.clearError()
        email.clearError()
        if (!name.getValue().length) return name.setError('Name is required')
        if (!email.getValue().length) return email.setError('Email is required')
        base.emit('submit', {
            name: name.getValue(),
            email: email.getValue()
        })
    }
    submit.cssClass({
        width: '200px',
        height: '58px',
        borderRadius: '12px'
    })
    name.cssClass({
        margin: '0 10px',

    })
    email.cssClass({
        margin: '0 10px',
        flex: '2',
    })
    base.cssClass({
        display: 'none',
        flexDirection: 'row',
        marginTop: '20px',
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0,0,0,.2)',
        position: 'absolute',
        left: '0',
        right: '0',
        top: '60px',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '160px',
        zIndex: '99999'
    })

    return Object.assign(base, {
        setEmail(e: string) {
            email.setValue(e)
        },
        show() {
            base.style({ display: 'flex' })
        },
        reset() {
            name.setValue('')
            email.setValue('')
        }
    })
}