import { Button } from '../../../base/components/native/button'
import { Div } from '../../../base/components/native/div'
import { ABSOLUTE, CENTER, EASE, WH } from '../../../base/helpers/style'
import { H } from '../../../base/utils/device'
import { IFlashcard } from '../../../liumus-interfaces/courses'
import services from '../../../services'
import { DButton } from '../../shared/d-button'

export const ReviewFlashcard = (data: any, courseId: string, level: number) => {

    const holder = Div()
    const q = processContent(data.q)
    const a = processContent(data.a)
    const face = Div(q)
    const back = Div(a)
    const buttons = Div('')
    const iknowit = DButton('I know it')
    const idontknowit = DButton('I don\'t know it', { color: 'crimson' })

    buttons.append(idontknowit, iknowit)

    const base = Div()
    holder.append(face, back)
    base.append(holder, buttons)

    base.on('mounted', () => {
        if (face.el.offsetHeight < face.el.scrollHeight) {
            face.el.style.justifyContent = 'flex-start'
        }
        if (back.el.offsetHeight < back.el.scrollHeight) {
            back.el.style.justifyContent = 'flex-start'
        }
    })

    iknowit.cssClass({
        marginLeft: '5px'
    })
    idontknowit.cssClass({
        marginRight: '5px'
    })
    buttons.cssClass({
        marginTop: '10px',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    })
    holder.cssClass({
        position: 'relative',
        height: 'calc(100% - 130px - env(safe-area-inset-bottom))',
        perspective: '2000px',
        cursor: 'pointer',
    })
    base.cssClass({
        height: '100%',
        width: '100%',
        marginBottom: '1rem',
        ...EASE(.16),
    })
    face.cssClass({
        ...CENTER,
        ...ABSOLUTE,
        ...WH('100%'),
        flexDirection: 'column',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(0deg)',
        transition: 'transform 0.5s',
        backgroundColor: 'white',
        borderRadius: '1rem',
        boxShadow: '0 0 0.5rem rgba(0,0,0,0.2)',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '1rem',
    })

    back.cssClass({
        ...CENTER,
        ...ABSOLUTE,
        ...WH('100%'),
        flexDirection: 'column',
        backfaceVisibility: 'hidden',
        transform: 'rotateY(180deg)',
        transition: 'transform 0.5s',
        backgroundColor: 'white',
        borderRadius: '1rem',
        boxShadow: '0 0 0.5rem rgba(0,0,0,0.2)',
        overflowY: 'auto',
        padding: '1rem',


    })

    function flip() {
        face.style({ transform: 'rotateY(180deg)' })
        back.style({ transform: 'rotateY(360deg)' })
        holder.el.onclick = flipBack
    }

    function flipBack() {
        face.style({ transform: 'rotateY(0deg)' })
        back.style({ transform: 'rotateY(180deg)' })
        holder.el.onclick = flip
    }

    holder.el.onclick = flipBack

    iknowit.el.onclick = () => {
        iknowit.text('Ok! continue')
        idontknowit.text('I didn\'t know it')
        iknowit.el.onclick = next.bind(null, level + 1)
        idontknowit.el.onclick = () => { next(level - 1) }
        flip()
    }

    idontknowit.el.onclick = () => {
        iknowit.text('Oh! I knew it')
        idontknowit.text('It\'s ok, continue')
        iknowit.el.onclick = () => { next(level + 1) }
        idontknowit.el.onclick = () => { next(level - 1) }
        flip()
    }

    async function next(newLevel: number) {
        await services.flashcards.changeLevel(data._id, courseId, newLevel)
        base.style({ opacity: '0' })
        setTimeout(() => {
            base.emit('next')
        }, 200)
    }
    return base
}

function processContent(c: string) {
    return c.replace(/<img /g, '<img style="max-width:100%;height:auto" ')
}