import { Div } from '../../../../base/components/native/div'
import { CENTER, ROUND, WH } from '../../../../base/helpers/style'
import { PlusOption } from './plus-option'

export const AddSlideOrFlashcard = () => {

    const base = Div()
    const plus = Div('+')
    const addSlide = PlusOption('+ Add slide')
    const addFlashcard = PlusOption('+ Add flashcards')
    const options = Div().append(addSlide, addFlashcard)
    base.append(plus, options)

    let t: NodeJS.Timeout
    // plus.el.onclick = () => {
        options.style({ display: 'flex' })
        plus.style({ display: 'none' })
        // t = setTimeout(hideOptions, 300);
    // }
    addSlide.el.onclick = () => base.emit('add-slide')
    addFlashcard.el.onclick = () => base.emit('add-flashcard')
    // base.el.addEventListener('mouseover', () => clearTimeout(t))
    // base.el.addEventListener('mouseleave', hideOptions);

    // function hideOptions() {
    //     options.style({ display: 'none' })
    //     plus.style({ display: 'block' })
    // }

    options.cssClass({
        display: 'none',
        justifyContent: 'space-between',
        // width: '300px'
    })
    plus.cssClass({
        ...WH(38),
    })
    base.cssClass({
        transform: 'scale(.8)',
        fontSize: '28px',
        color: '#fff',
        // backgroundColor: '#dbdbdb',
        cursor: 'pointer',
        margin: '1px auto',
        minWidth: '40px',
        height: '40px',
        borderRadius: '20px',
        marginBottom: '30px',
        marginTop: '30px',
        // ...WH(40),
        ...CENTER,
        display: 'inline-flex',
        '&:hover': {
            opacity: 0.8,
        }
    })

    return base
}