import { IKeys } from './keys'

const keyLabels: Partial<IKeys> = {
    COURSES: 'Courses',
    QUIZZES: 'Quizzes',
    FLASHCARDS: 'Flashcards',
    PEOPLE: 'People',
    ANALYTICS: 'Analytics',
    SETTINGS: 'Settings',
    CHANGE_LANGUAGE: 'Change language',
    LOGIN: 'Login',
    PLEASE_WAIT: 'Please wait',
    LOGIN_WITH_GOOGLE: 'Login with Google',
    ADD_A_NEW_COURSE: 'Add a new course',
    CREATE_A_NEW_COURSE: 'Create a new course',
    SAVE: 'Save',
    SAVING: 'Saving',   
    SAVED: 'Saved',
    MEMORIZE: 'Memorize',
    MEMORIZING: 'Memorizing',
    MEMORIZED: 'Memorized',
    CREATED_ON: 'Created on',
    AT: 'at',
    ADD_SLIDE_WELCOME_MESSAGE: 'Click to create a new slide!',
    NEW_COURSE: 'New course',
    NEW_QUIZ: 'New quiz',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    CREATE: 'Create',
    CLICK_TO_EDIT: 'Click to edit',
    SLIDE_CONTENT: 'Slide content',
    NO_COURSES_AVAILABLE: 'No courses are available',
    NO_QUIZZES_AVAILABLE: 'No quizzes are available',
    NO_FLASHCARDS_AVAILABLE: 'No flashcards are available',
    NO_PEOPLE_AVAILABLE: 'No people are available',
    ADD_NEW_EMPLOYEE: 'Add a new employee',
    NO_ANALYTICS_AVAILABLE: 'No analytics are available',
    NO_SETTINGS_AVAILABLE: 'No settings are available',
    NAME: 'Name',
    EMAIL: 'Email',
    ROLE: 'Role',
    PHONE: 'Phone',
    REVIEW: 'Review',
}

export default keyLabels