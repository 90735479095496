import { Div } from '../../../../base/components/native/div'
import { EASE } from '../../../../base/helpers/style'
import router from '../../../../base/lib/router'
import emitter from '../../../../base/utils/emitter'

export const CourseOption = (title: string, target = '') => {

    const base = Div(title)
    base.el.onclick = () => {
        const courseId = location.pathname.match(/courses\/(\w+)/)?.[1]
        const query = location.search
        base.emit('select', target)
    }
    
    base.cssClass({
        margin: '0px 0 30px',
        cursor: 'pointer',
        fontWeight: '100',
        fontSize: '18px',
        color: '#000',
        backgroundColor: 'transparent',
        width: 'fit-content',
        padding: '4px 11px 6px',
        borderRadius: '18px',
        display: 'none',
        ...EASE(.16),
        '&:hover': {
            backgroundColor: '#00000044',
        },
        '&:active': {
            backgroundColor: '#00000088',
        },
    })

    return Object.assign(base, {
        select: () => {
            base.style({
                fontWeight: '500',
                color: '#fff',
                backgroundColor: '#252525c9',
            })
        },
        unselect: () => {
            base.style({
                fontWeight: '100',
                color: '#000',
                backgroundColor: 'transparent',
            })
        },
        hide: () => {
            base.style({
                display: 'none'
            })
        },
        show: () => {
            base.style({
                display: 'block'
            })
        }
    })
}