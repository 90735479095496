import { ICourse, ICoursePayload } from '../liumus-interfaces/courses'
import api from './api'

async function create(data: ICoursePayload) {
    return api.post('/api/courses', data)
}

async function getAll() {
    return api.get('/api/courses')
}

async function getOne(id: string) {
    // Todo: get form the cache if we have it and if the TTL is not expired
    // if not, get from the server
    return api.get(`/api/courses/${id}`)
}

async function update(data: Partial<ICourse>) {
    return api.put(`/api/courses/${data._id}`, data)
}

async function remove(id: string) {
    return api.delete(`/api/courses/${id}`)
}

async function getSubscribers(id: string) {
    return api.get(`/api/courses/${id}/subscribers`)
}

async function subscribe(id: string, personId: string) {
    return api.post(`/api/courses/${id}/subscribe`, { personId })
}

async function invite(id: string, name: string, email: string) {
    return api.post(`/api/courses/${id}/invite`, { name, email })
}

export default {
    create,
    getAll,
    getOne,
    update,
    remove,
    getSubscribers,
    subscribe,
    invite
}