import { Div } from '../../../base/components/native/div'
import ldb from '../../../base/lib/ldb'
import { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { IFlashcard } from '../../../liumus-interfaces/courses'
import services from '../../../services'
import { Loading } from '../../shared/loading'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'
import { ReviewFlashcard } from './review-flashcard'

export const ReviewPage = () => {

    const base = Div()
    const header = PageTitle('Review your flashcards')
    const contents = PageContents()
    base.append(header, contents)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    contents.style({ padding: '20px 40px' })
    let cursor = 0
    const flashcards: {_id: string, flashcardId: string, level: number, courseId: string}[] = []
    async function loadItemsToReview() {
        ldb.set('saw-the-review-notification', true)
        contents.append(Loading())
        try {
            const itemsToReview = await services.flashcards.getToReview() as any[]
            flashcards.push(...itemsToReview)
        } catch (error) {
            contents.empty()
            contents.append(Div('Error loading people'))
        }
        showNext()
    }
    async function showNext() {
        contents.empty()
        if (cursor >= flashcards.length) {
            contents.append(Div('No more flashcards to review!'))
            ldb.set('saw-the-review-notification', false)
            return
        }
        const item = flashcards[cursor];
        const { data } = await services.flashcards.getOne(item.flashcardId)
        const card = ReviewFlashcard(data as IFlashcard, item.courseId, item.level)
        contents.append(card)
        card.on('next', () => {
            cursor++;
            showNext();  
          });
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to == '/') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        loadItemsToReview()
    }

    return Object.assign(base, { enter, exit })
}