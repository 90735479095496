import ai from './ai'
import api from './api'
import auth from './auth'
import cloudinary from './cloudinary'
import courses from './courses'
import db from './db'
import flashcards from './flashcards'
import people from './people'
import quizzes from './quizzes'
import slides from './slides'
import translations from './translations'

export default {
    db: db,
    auth: auth,
    courses: courses,
    people: people,
    flashcards: flashcards,
    slides: slides,
    api: api,
    translations: translations,
    cloudinary: cloudinary,
    ai: ai,
    quizzes: quizzes

}