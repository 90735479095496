function cleanPaste(html: string) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');

    // Remove all style and class attributes
    Array.from(doc.body.querySelectorAll('*')).forEach(function (node) {
        node.removeAttribute('style');
        node.removeAttribute('class');
    });

    return doc.body.innerHTML
}

function selectAll(el: HTMLElement) {
    let range = document.createRange() // Create a range
    range.selectNodeContents(el) // Select the entire content of the element

    let selection = window.getSelection() // Get the current selection
    if (selection) {
        selection.removeAllRanges() // Remove any existing selections
        selection.addRange(range) // Add the new range (our entire content)
    }
}

function handlePaste(html: string) {
    const selection = window.getSelection()
    if (selection?.rangeCount) {
        const range = selection.getRangeAt(0)
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = html

        range.deleteContents() // Delete any selected content

        // Insert child nodes in the correct order:
        let lastChild;
        while (lastChild = tempDiv.lastChild) {
            range.insertNode(lastChild); // This automatically removes it from tempDiv
        }

        range.collapse(false) // Position the cursor after the inserted content
    }
}



export default {
    cleanPaste,
    selectAll,
    handlePaste
}