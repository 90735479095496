import { Button } from '../../../../base/components/native/button'
import { Div } from '../../../../base/components/native/div'
import device from '../../../../base/helpers/device'
import { Y, X } from '../../../../base/helpers/style'
import router, { IRouteParams } from '../../../../base/lib/router'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import { Loading } from '../../../shared/loading'
import { CourseContent } from '../course-contents'
import { ActionButton } from './action-button'
import { SubCourseButtons } from './sub-course-buttons'

export const CourseInfo = () => {
    const b = Button('Click me')
    b.el.onclick = () => {
        router.goto('quizzes')
    }
    const base = Div()
    // base.append(b)
    const contents = CourseContent()
    const buttons = SubCourseButtons()
    const save = ActionButton('Save course', handleSave)
    buttons.append(save)
    base.append(contents, buttons)
    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        ...Y(0),
        ...X(window.innerWidth)
    })

    let i: any = null
    function exit({ to }: IRouteParams) {
        clearInterval(i)
        base.style(helpers.styles.PAGE_EXIT_RIGHT_STYLE)
        // if (to?.includes('/course')) base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
        // if (to?.includes('/settings')) base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ params, from, data }: IRouteParams) {
        
        // i = setInterval(handleSave, 20000)
        // if (!params.id) return
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        // i = setInterval(handleSave, 20000)
        save.show()
        contents.load(data.course)
        // contents.append(Loading())

        // const roles = remote.data.user_id === uid || remote.data.admins?.includes(uid) ? 'admin' : 'user'
        // contents.load(remote.data)
        // if (roles === 'user') {
        //     save.style({ display: 'none' })
        // } else {
        //     save.style({ display: 'block' })
        // }

        // save.on('click', () => {
        //     clearInterval(i)
        //     // i = setInterval(handleSave, 20000)
        //     handleSave()
        // })
    }

    async function handleSave() {
        save.disable()
        const loading = Loading()
        save.append(loading)
        const data = contents.getData()
        
        await services.courses.update(data)
        await contents.update()
        loading.remove()
        save.enable()
    }

    return Object.assign(base, { enter, exit, load: contents.load })
}