import { Div } from '../../../../base/components/native/div'
import ldb from '../../../../base/lib/ldb'
import images from '../../../../configs/images'
import services from '../../../../services'
import { Feature } from './feature'

export const Features = () => {

    const base = Div()
    const translations = services.translations.all()

    const courses =   Feature(translations['COURSES'],   images.ICONS.COURSES,   '/courses',  'Dive into a collection of bite-sized learning modules tailored for your organization')
    const people =    Feature(translations['PEOPLE'],    images.ICONS.PEOPLE,    '/people',   'Invite and Manage users and keep track of the activities')
    const quizzes =   Feature(translations['QUIZZES'],   images.ICONS.QUIZZES,   '/quizzes',  'Create quizzes to test your understanding and retention of course materials')
    const analytics = Feature(translations['ANALYTICS'], images.ICONS.ANALYTICS, '/analytics','Track user\'s learning progress, strengths, and areas for improvement with detailed insights',  { disabled: true })
    const review =    Feature(translations['REVIEW'],    images.ICONS.REVIEW,    '/review',   'Revisit and reinforce your knowledge through a quick overview of recently learned content' )
    const setting =   Feature(translations['SETTINGS'],  images.ICONS.SETTINGS,  '/setting',  'Personalize your learning experience and adjust app preferences')

    base.append(courses, people, quizzes, analytics, review, setting)

    base.cssClass({
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'baseline',
        justifyContent: 'center',
        top: '64px',
        fontSize: '18px',
        zIndex: '9999',
    })

    return Object.assign(base, {
        update() {
            const isAdmin = ldb.get('liumus-roles') === 'admin'
            isAdmin ? people.show() : people.hide()
            isAdmin ? quizzes.show() : quizzes.hide()
            isAdmin ? analytics.show() : analytics.hide()
            review.show()
        }
    })
}