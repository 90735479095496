import { Div } from '../../../base/components/native/div'
import { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import { Loading } from '../../shared/loading'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'
import { ActionButton } from '../courses/course-page/action-button'
import { Questions } from './questions'

export const QuizPage = () => {

    const translations = services.translations.all()
    const base = Div()
    const header = PageTitle('')
    const contents = PageContents()
    const save = ActionButton()
    base.append(header, save, contents)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    async function load(quizId: string) {
        contents.append(Loading())
        contents.empty()
        const raw = await services.quizzes.getOne(quizId)
        const quiz = raw.data
        header.text(quiz.title)
        const questions = Questions(quiz.questions)
        contents.append(questions)
        save.style({ display: 'block' })
        save.el.onclick = () => {
            const body = {
                title: quiz.title,
                questions: questions.getValue()
            }
            services.quizes.update(quizId, body)
        }
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ query, from, to, data, params }: IRouteParams) {
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        load(params.id)
    }

    return Object.assign(base, { enter, exit })
}