import { Div } from '../../base/components/native/div'
import { CENTER } from '../../base/helpers/style'
import router from '../../base/lib/router'

export const AddButton = (title: string, target: string) => {

    const base = Div(title)
    base.el.onclick = () => router.goto(target)

    base.cssClass({
        position: 'absolute',
        top: '10px',
        height: '42px',
        right: '20px',
        cursor: 'pointer',
        color: '#fff',
        fontSize: '16px',
        padding: '8px 15px',
        backgroundColor: '#14c082',
        borderRadius: '21px',
        minWidth: '42px',
        ...CENTER,
        '&:hover': {
            backgroundColor: '#0d9e57',
        },
    })

    return base
}