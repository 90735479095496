import { Div } from '../../../base/components/native/div'
import { IRouteParams } from '../../../base/lib/router'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import { PageContents } from '../../shared/page-contents'
import { HomeHeader } from '../home/home-header'

export const Analytics = () => {

    const base = Div()
    const header = HomeHeader()
    const contents = PageContents()
    base.append(header, contents)
    contents.append(Div('Analytics'))
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
    }

    async function enter({ query, from, to, data }: IRouteParams) {
        // if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}