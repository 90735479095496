import { A } from '../../base/components/native/a'

export const HeaderLink = (href: string, title: string) => {

    const base = A(href, title)
    base.cssClass({
        textDecoration: 'none',
        color: '#777',
        marginRight: '5px',
        fontWeight: '100',
        '&:hover': {
            opacity: '0.7',
        }
    })

    return base
}