import { SVG } from '../../base/components/native/svg'

export const Loading = (fill?: string) => {

    const base = SVG(`
    <circle cx="30" cy="50" fill="#dddddd" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.5s"></animate>
    </circle>
    <circle cx="70" cy="50" fill="#66c09f" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="0s"></animate>
    </circle>
    <circle cx="30" cy="50" fill="#dddddd" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.5s"></animate>
        <animate attributeName="fill-opacity" values="0;0;1;1" calcMode="discrete" keyTimes="0;0.499;0.5;1" dur="1s" repeatCount="indefinite"></animate>
    </circle>

`, 100, 30)
    base.cssClass({
        fill: fill || '#00000088',
        '&.dark': {
            fill: fill || '#ffffff88',
        },
        opacity: '1',
        maxWidth: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: '90px',
    })

    return base
}
