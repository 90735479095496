import { Div } from '../../../base/components/native/div'
import router from '../../../base/lib/router'

export const CourseItemTitle = (course: any) => {
    
    const base = Div(course.title)
    const isAdmin = Div('(Admin)')
    isAdmin.cssClass({
        color: '#aaa',
        fontSize: '14px',
        fontWeight: '100',
    })
    if(course.isAdmin) base.append(isAdmin)
    base.el.addEventListener('click', () => router.goto(`/courses/${course._id}/info`, { title: course.title, isAdmin: course.isAdmin }))
    base.cssClass({
        margin: '0px 0 10px',
        fontWeight: '100',
        fontSize: '18px',
        cursor: 'pointer',
        padding: '5px 10px',
        marginLeft: '-10px',
        width: 'fit-content',
        borderRadius: '17px',
        '&:hover': {
            opacity: '.8'
        }
    })

    return Object.assign(base, {
        getId: () => course._id,
        select: () => {
            base.style({
                backgroundColor: '#eee',
            })
        },
        deselect: () => {
            base.style({
                backgroundColor: 'white',
            })
        }
    })
}