import { Div } from '../../../../base/components/native/div'
import { Loading } from '../../../shared/loading'
import { CourseLearnersListItem } from './course-learners-list-item'

export const CourseLearnersList = () => {

    const base = Div()
    base.cssClass({
        padding: '20px',
        overflowY: 'auto',
        height: 'calc(100vh - 200px)'
    })

    function fill(items: any[] = []) {
        base.empty()
        items.forEach(item => {
            const result = CourseLearnersListItem(item?.person?.[0])
            base.append(result)
        })
    }
    function wait() {
        base.append(Loading())
    }

    return Object.assign(base, { fill, wait })
}