import { Div } from '../../../base/components/native/div'
import { EMAIL_REGEX } from '../../../base/helpers/regex'
import router, { IRouteParams } from '../../../base/lib/router'
import helpers from '../../../helpers'
import services from '../../../services'
import { DButton } from '../../shared/d-button'
import { DInput } from '../../shared/d-input'
import { PageContents } from '../../shared/page-contents'
import { PageTitle } from '../../shared/page-title'

export const NewPeople = () => {

    const translations = services.translations.all()
    const base = Div()
    const header = PageTitle(translations['ADD_NEW_EMPLOYEE'])
    const contents = PageContents()
    const name = DInput(translations['NAME'])
    const email = DInput(translations['EMAIL'])
    // const phone = DInput(translations['PHONE'])
    const submit = DButton(translations['CREATE'])
    submit.el.onclick = handleSubmission
    contents.append(name, email, submit)
    base.append(header, contents)
    contents.style({ paddingTop: '20px', paddingRight: '60px' })
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)

    async function handleSubmission() {
        if (!name.getValue()) {
            name.setError('Name is required') // Todo: translate
            return
        }
        if (!email.getValue()) {
            email.setError('Email is required')
            return
        }
        if (!email.getValue().match(EMAIL_REGEX)) {
            email.setError('Invalid email')
            return
        }

        submit.disable()
        const data = {
            name: name.getValue(),
            email: email.getValue().toLowerCase(),
            // phone: phone.getValue(),
            // course_id : location.pathname.split('/')[2],
            role: 'user',
        }
        try {

            const results = await services.people.create(data)
            if (results.status === 409) {
                email.setError('Person is already invited')
                submit.enable()
                return
            }
            submit.enable()
            name.setValue('')
            email.setValue('')
            // phone.setValue('')
            router.back()
        } catch (error) {
            // errors like person is already invided 409
            // or email is already in use 400
            // or network error

            submit.enable()
            if (error.response?.status === 409) {
                email.setError('Person is already invited')
            }
            if (error.response?.status === 400) {
                email.setError('Email is already in use')
            }

        }
    }

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to?.includes('/people')) base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }


    async function enter({ query, from, to, data }: IRouteParams) {
        // await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}