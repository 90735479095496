export default {
    RES: {
        LOGO: '/images/liumus-logo.svg',
    },
    ICONS: {
        EDIT: '/images/edit-icon.svg',
        LOCK: '/images/lock.svg',
        CONFIRM: '/images/confirm-icon.svg',
        DELETE: '/images/trash-icon.svg',
        CANCEL: '/images/cancel-icon.svg',
        ADD: '/images/add-icon.svg',
        DOWNLOAD: '/images/download-icon.svg',
        BACK: '/images/back-icon.svg',
        GOOGLE: '/images/google.svg',
        SPIRAL: '/images/google.svg',
        STAR: '/images/star-icon.svg',
        COLORS: '/images/colors-icon.svg',
        X: '/images/x-icon.svg',
        IMAGE: '/images/image.svg',
        FLIP: '/images/flip-right-icon.svg',
        CHECK: '/images/check.svg',
        MAGIC: '/images/magic-wand.svg',
        PEOPLE: '/images/people.svg',
        QUIZZES: '/images/quizzes.svg',
        ANALYTICS: '/images/analytics.svg',
        REVIEW: '/images/review.svg',
        SETTINGS: '/images/settings.svg',
        COURSES: '/images/courses.svg',
        
    }
}