import { Div } from '../../../../base/components/native/div'
import emitter from '../../../../base/utils/emitter'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import authUser from '../../../../services/firebase/auth-user'
import { HeaderLink } from '../../../shared/header-link'
import { PageTitle } from '../../../shared/page-title'
import { CourseAttempts } from './course-attempts'
import { CourseInfo } from './course-info'
import { CourseLearners } from './course-learners'
import { CourseOptions } from './course-options'
import { CourseQuiz } from './course-quiz'
import { CourseSettings } from './course-settings'
import { SubView } from './sub-view'
import { ICourse } from '../../../../liumus-interfaces/courses'
import router, { IPage, IRouteParams } from '../../../../base/lib/router'
import device from '../../../../base/helpers/device'
import { CourseOptionsPage } from './course-options-page'

export const CoursePage = () => {

    const base = Div()
    const header = PageTitle('')
    const options = CourseOptions()
    const view = SubView()
    const coursesLink = HeaderLink('/courses', 'Courses / ')
    const homeLink = HeaderLink('/', 'Home / ')
    if (!device.isMobile()) header.prepend(coursesLink, homeLink)
    base.append(header, options, view)
    base.cssClass(helpers.styles.PAGE_BASE_STYLE)
    if (device.isMobile()) options.style({ display: 'none' })

    const routes: any = {
        '/courses/:id': CourseOptionsPage,
        '/courses/:id/info': CourseInfo,
        '/courses/:id/learners': CourseLearners,
        '/courses/:id/quizzes': CourseQuiz,
        '/courses/:id/attempts': CourseAttempts,
        '/courses/:id/settings': CourseSettings, //device.isMobile() ? Course : Courses,
    }
    router.init({
        root: '',
        routes,
        view,
        preventAutoStart: true
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_UP_STYLE)
        if (to === '/courses') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
        // emitter.off('route-changed', handleSubRoute)
    }

    let __remoteData: any
    async function enter({ params, from, data, to }: IRouteParams) {
        console.log('ENTER COURSE PAGE', params, data, to);
        
        if (!params.id) return
        if (helpers.device.isMobile()) await waitFor(200)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
        header.text(data?.title || '')
        const uid = (await authUser.getUser())?.uid
        if (!uid) {
            const message = `You need to login to see this course<br><a href="/login?redirect=/courses/${params.id}" target='_self'>Click here to login</a>`
            return view.notify(message)
        }
        const remote = await services.courses.getOne(params.id) as any
        if (!remote.data) {
            const message = `Course not found or is not accessible for you.<br>Please try to switch to another account or contact the owner of the course.<br><a href="/login?redirect=/courses/${params.id}" target='_self'>Click here to login with another account</a>`
            return view.notify(message)
        }
        header.text(remote.data.title)
        const roles = remote.data.user_id === uid || remote.data.admins?.includes(uid) ? ['admin'] : ['user']
        options.applyRoles(roles)
        __remoteData = { roles, course: remote.data }
        document.title = 'Course page:' + remote.data.title
        // let sub = location.pathname.split('/' + remote.data._id).pop()
        options.on('select', (path: string) => {
            router.goto(`/courses/${remote.data._id}/${path}`, __remoteData)
        })
        // const possibleSubPath = location.pathname.split('/' + remote.data._id).pop() || '/info'
        // console.log('> REMOTE DATA:',  __remoteData );
        
        // if (!device.isMobile()) router.goto(`/courses/${remote.data._id}/${possibleSubPath}`, __remoteData)
    }

    emitter.on('course-updated', (__course: ICourse) => {
        if (__course._id !== __remoteData.course._id) return
        header.text(__course.title)
        __remoteData.course = __course
    })

    return Object.assign(base, { enter, exit })
}