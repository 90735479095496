import { Div } from '../../../base/components/native/div'
import { IPerson } from '../../../liumus-interfaces/people'

export const Person = (person: IPerson) => {

    const base = Div()
    const name = Div(person.name)
    const email = Div(person.email)
    base.append(name, email)

    email.cssClass({
        fontSize: '14px',
        color: '#999',
    })
    base.cssClass({
        cursor: 'pointer',
        marginBottom: '20px',
    })

    return base
}