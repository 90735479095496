import { Div } from '../../../../base/components/native/div'
import { PlusOption } from './plus-option'

export const GenerateFlashcards = () => {

    const base = PlusOption('Generate flashcards')
    base.cssClass({
        backgroundColor: '#fad5ff'
    })

    return base
}