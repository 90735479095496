import { ICourse, ICoursePayload } from '../liumus-interfaces/courses'
import { IPerson } from '../liumus-interfaces/people'
import api from './api'

async function create(data: IPerson) {
    return api.post('/api/people', data)
}

async function all(skip = 0, limit = 100) {
    return api.get<{ items: IPerson[] }>('/api/people')
}

async function find(search: string | { [key: string]: string }) {
    if (typeof search === 'string') {
        return api.get(`/api/people?q=${search}`)
    } else {
        const params = new URLSearchParams()
        for (const key in search) {
            params.append(key, search[key])
        }
        const queryString = params.toString()
        return api.get(`/api/people?q=${queryString}`)
    }
}

async function update(data: ICourse) {
    return api.put(`/api/people/${data._id}`, data)
}

async function remove(id: string) {
    return api.delete(`/api/people/${id}`)
}

async function getMany(ids: string[]) {
    // Todo : for small group of users only. Develop later
    // const params = new URLSearchParams()
    // for (const id of ids) {
    //     params.append('ids', id)
    // }
    // const queryString = params.toString()
    return api.get(`/api/people?ids=${ids.join(',')}`)
}

export default {
    create,
    all,
    find,
    update,
    remove,
    getMany
}