import { Div } from '../../../base/components/native/div'
import { Img } from '../../../base/components/native/img'
import { CENTER } from '../../../base/helpers/style'
import images from '../../../configs/images'

export const RemoveQuestionButton = () => {

    const base = Div('✕')

    base.cssClass({
        position: 'absolute',
        left: '-42px',
        top: '-5px',
        borderRadius: '50%',
        border: '1px solid #bbb',
        width: '32px',
        height: '32px',
        color: '#bbb',
        ...CENTER,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eee'
        },
        '&:active': {
            backgroundColor: '#ccc'
        }
    })

    return base
}