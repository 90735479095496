import { Div } from '../../../../base/components/native/div'
import { EMAIL_REGEX } from '../../../../base/helpers/regex'
import { ABSOLUTE } from '../../../../base/helpers/style'
import services from '../../../../services'
import { DButton } from '../../../shared/d-button'
import { DInput } from '../../../shared/d-input'
import { AddCourseLearnersNewUser } from './add-course-learners-new-user'
import { CourseLearnersListItem } from './course-learners-list-item'

export const AddCourseLearners = () => {

    let courseId = ''
    const base = Div()
    const input = DInput('Learner email or name', 'email')
    const results = Div()
    const add = AddCourseLearnersNewUser()
    base.append(input, results, add)
    add.on('submit', handleSendInvitation)
    input.on('input', handleInput)

    async function handleInput() {
        const value = input.getValue()
        if (!value.length) return results.style({ display: 'none' })
        results.style({ display: 'block' })
        const { data: { items } } = await services.people.find(value) as any
        results.empty()
        if (!items?.length) {
            if (value.match(EMAIL_REGEX)) {
                add.setEmail(value)
                add.show()
                return results.style({ display: 'none' })
            } else {
                add.style({ display: 'none' })
                add.reset()
                return results.append(Div('No results found'))
            }

        }
        items.forEach((person: any) => {
            const item = CourseLearnersListItem(person)
            item.el.onclick = () => handleSubmit(person)
            results.append(item)
        })
    }

    async function handleSubmit(person: any) {
        const response = await services.courses.subscribe(courseId, person._id)
        input.setValue('')
        results.style({ display: 'none' })
        if (!response.data.existing) base.emit('subscribed', person)
    }

    async function handleSendInvitation({ name, email }: any) {
        const response = await services.courses.invite(courseId, name, email)
        input.setValue('')
        add.style({ display: 'none' })
        if (!response.data.existing) base.emit('subscribed', { name, email })
    }




    input.cssClass({
        margin: '0',
        width: '100%'
    })
    results.cssClass({
        ...ABSOLUTE,
        top: '60px',
        marginTop: '20px',
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        minHeight: '255px',
        maxHeight: '600px',
        overflowY: 'auto',
        borderRadius: '16px',
        padding: '20px',
        display: 'none',
    })
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    })

    return Object.assign(base, { setCourseId: (id: string) => courseId = id })
}