import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAY6UdWOBFTVXhteIsDXC0deeAYi9tC6AY",
    authDomain: "liumus-app.firebaseapp.com",
    projectId: "liumus-app",
    storageBucket: "liumus-app.appspot.com",
    messagingSenderId: "697161407571",
    appId: "1:697161407571:web:63b2fd02e7349fc1c2baa6",
    measurementId: "G-9X74HERE0L"
}

const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
const auth = getAuth(app)
let authPromiseResolve: any
let authPromise = new Promise((resolve) => {
    authPromiseResolve = resolve
})
onAuthStateChanged(auth, (user) => {
    authPromiseResolve()
})

async function getUser() {
    await authPromise
    const user = auth.currentUser
    return user
}

async function getToken() {
    await authPromise
    const user = auth.currentUser
    if (user) {
        return await user.getIdToken()
    } else {
        throw new Error('No user currently logged in')
    }
}

export default {
    getUser,
    getToken,
    auth
}

