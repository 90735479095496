import { Div } from '../../../../base/components/native/div'
import device from '../../../../base/helpers/device'

export const SubCourseButtons = () => {

    const base = Div()
    base.cssClass({
        position:  'fixed',
        // right: '5px',
        // top: device.isMobile() ? 'unset':'-53px',
        right: '0',
        left: '0',
        bottom: device.isMobile() ? 'calc(env(safe-area-inset-bottom) + 35px)':'5px',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
    })

    return base
}