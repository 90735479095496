import { Div } from '../../../../base/components/native/div'
import device from '../../../../base/helpers/device'

export const SubView = () => {

    const base = Div()
    base.cssClass({
        height: '100%',
        marginLeft: device.isMobile() ? '': '200px',
        position: 'relative',
    })

    return Object.assign(base, {
        notify: (message: string) => {
            base.empty()
            const msg = Notif(message)
            base.append(msg)
        }
    })
}

const Notif = (message: string) => {
    const base = Div(message)
    base.cssClass({
        padding: '39px'
    })
    return base
}