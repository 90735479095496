import { Div } from '../../../../base/components/native/div'
import { Img } from '../../../../base/components/native/img'
import { EASE, HIDE, SHOW } from '../../../../base/helpers/style'
import images from '../../../../configs/images'
import helpers from '../../../../helpers'
import { IFlashcard, ICourseFlashcards } from '../../../../liumus-interfaces/courses'
import models from '../../../../models'
import services from '../../../../services'
import { CourseFlashcard } from './flashcard'
import { AddSlideOrFlashcard } from './add-slide-or-flashcard'
import { SimplePlus } from './simple-plus'
import { CourseSlide } from './slide'

export const CourseFlashcards = (flashcards: ICourseFlashcards, options:any = {}) => {

    const base = Div()
    const plus = AddSlideOrFlashcard()
    const flashcardEls = flashcards.items.filter(Boolean).map(flashcard => CourseFlashcard(flashcard, options))
    flashcardEls.forEach(flashcardEl => {
        flashcardEl.on('remove', handleRemove)
    })
    const container = Div()
    const endPlus = SimplePlus()
    const remove = Img(images.ICONS.DELETE, { width: 24 })
    container.append(endPlus,...flashcardEls)
    base.append(plus,  container)
    // remove.el.onclick = base.remove
    endPlus.el.onclick = async () => {
        const flashcardContent = models.flashcard.create()
        const response = await services.flashcards.create(flashcardContent)
        const newFlashcard = CourseFlashcard(response.data)
        container.appendAfter(endPlus, newFlashcard)
        container.el.scrollLeft = container.el.scrollWidth
        container.el.style.scrollBehavior = 'smooth'
        newFlashcard.on('remove',handleRemove)
    }
    plus.on('add-slide', async () => {
        const slideContents = models.slide.create()
        const response = await services.slides.create(slideContents)
        const newSlide = CourseSlide(response.data)
        base.parent.appendBefore(base, newSlide)
    })
    plus.on('add-flashcard', async () => {
        const flashcardContent = models.flashcard.create()
        const response = await services.flashcards.create(flashcardContent)
        const newSlide = CourseFlashcards({ type: 'flashcards', items: [response.data]})
        base.parent.appendBefore(base, newSlide)
    })
    base.el.onmouseenter = () => {remove.style(SHOW) }
    base.el.onmouseleave = () => {remove.style(HIDE) }


    plus.style({
        marginTop: '30px'
    })
    remove.cssClass({
        position: 'absolute',
        right: '0px',
        bottom: '30px',
        cursor: 'pointer',
        ...HIDE,
    })
    const newLocal = '0'
    endPlus.cssClass({
        minWidth: '36px',
        display: 'flex',
        flexDirection: 'row', 
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '30px',
        // position: 'absolute',
        // left : newLocal
    })
    container.cssClass({
        // backgroundColor: '#00000014',
        borderRadius: helpers.device.isMobile()? '0': '20px',
        display: 'flex',
        overflowX: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '80px',
        padding: '36px 22px 36px 40px',
        justifyContent: 'center',

    })
    base.on('mounted', () => {
        if (container.el.scrollWidth > container.el.clientWidth) {
            container.style({
                justifyContent: 'flex-start',
            })
        }
    })
    if (options.roles === 'user') {
        plus.style({display: 'none'}, 10)
        remove.style({display: 'none'}, 10)
        endPlus.style({display: 'none'}, 10)
    }
    base.cssClass({
        overflow: 'scroll',
        maxWidth: '1200px',
        margin: '0 auto 0',
        position : 'relative',
    })

    function handleRemove() {
        console.log('remove called');
        setTimeout(() => {
            
            const remaining = container.getChildren().filter(child => child?.getId?.())
            console.log('remaining', remaining);
            if (remaining.length === 0) {
                base.remove()
            }
        }, 100);
        
        
    }

    return Object.assign(base, {
        getValue: () => {
            const items = flashcardEls.map(flashcardEl => flashcardEl.getValue())
            console.log('items', items);
            
            return { ...flashcards, items }
        },
        async update() {
            container.getChildren().forEach(async child => {
                if (child.update) {
                    await child.update()
                }
            })
            // flashcards.content = content.el.innerHTML
            // slide.lastChange = new Date().toISOString()
            // await services.slides.update(slide)
        },
        // getData: () => ({ _id: flashcards._id, type: 'slide' })
        getData: () => ({ type: 'flashcards', items: container.getChildren().map(child => child.getId?.()).filter(Boolean)})
    })
}