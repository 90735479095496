import { Ghost, ghostify } from '../../base/components/advanced/ghost/ghost'
import { MenuIconBar } from './menu-icon/menu-icon-bar'
import { CENTER, EASE, HIDE, SHOW, Y } from '../../base/helpers/style'
import _emitter  from '../../base/utils/emitter'
import { PASSIVE } from '../../base/utils/passive-support'
import { Div } from '../../base/components/native/div'
import device from '../../base/helpers/device'
import router from '../../base/lib/router'

export const BackIcon = () => {
    const height = 2
    const base = Div()
    const top = MenuIconBar(height)
    const bottom = MenuIconBar(height)

    router.on('change', (r: any) => r.path == '/' ? hide() : show())
        
    base.el.onclick = () => router.back()

    top.style({
        bottom: '27px',
        width: '14px',
        backgroundColor:'black',

        // right: '10px',
        position: 'absolute',
        transform: `rotateZ(45deg)`
    })
    bottom.style({
        top: '27px',
        width: '14px',
        backgroundColor:'black',

        // right: '10px',
        position: 'absolute',
        transform: `rotateZ(-45deg)`
    })

    const ghost = Ghost({ bg: 'white' })
    base.el.addEventListener('touchstart', (e) => {
        ghost.activate(e.touches[0].pageX, e.touches[0].pageY)
        base.style({
            transform: 'scale(.9)'
        })
    }, PASSIVE)
    base.el.addEventListener('touchend', () => {
        ghost.deactivate()
        base.style({
            transform: 'scale(1)'
        })
    })
    base.append(top, bottom, ghost)

    base.cssClass({
        position: 'fixed',
        left: `0`,
        top: `calc(0px + env(safe-area-inset-top))`,
        margin: '0',
        width: '64px',
        height: '64px',
        ...EASE(device.isMobile()?.16: 0),
        zIndex: '99999',
        opacity: '0',
        ...Y(device.isMobile() ? 40 : 2),
        cursor: 'pointer',
        '&:hover': {
            opacity: '.8'
        },
        ...CENTER
    })
    ghostify(base)
    function show() {
        base.style({
            ...SHOW,
            ...Y(2)
        }, {delay: 0})
    }
    function hide() {
        base.style({
            ...HIDE,
            ...Y(60)

        })
    }


    return base
}