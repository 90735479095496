import { Div } from '../../../../base/components/native/div'

export const FeatureDescription = (text: string) => {

    const base = Div(text)
    base.cssClass({
        fontSize: '14px',
        color: 'gray',
        fontWeight: '100',
        marginTop: '5px'
    })

    return base
}