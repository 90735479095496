import { IBaseComponent } from '../../../../base/components/base'
import { Div } from '../../../../base/components/native/div'
import { ABSOLUTE, CENTER, EASE, HIDE, SHOW, Y } from '../../../../base/helpers/style'
import { Editor } from '../../../../editor/editor'
import { IEditorOptions } from '../../../../editor/interfaces/editor'
// import Quill from 'quill';

export const Toolbar = (components: IBaseComponent<any>[], options= []) => {

    const base = Div()
    // const t = Div('T')
    // t.el.id = 'ql-toolbar'
    // const b = Div('B')
    // b.el.id = 'editor'
    // base.append(t,b)

    // let options = {
    //     debug: 'info',
    //     modules: {
    //       toolbar: '#ql-toolbar'
    //     },
    //     placeholder: 'Compose an epic...',
    //     readOnly: true,
    //     theme: 'snow'
    //   };
    // var editor = new Quill('#editor', options)
    
    const editor = Editor(components)
    base.append(editor)
    base.cssClass({
        ...EASE(.16),
        ...CENTER,
        height: '40px',
        zIndex: '999999',
        position: 'relative',
        width: '100%',
        
        // ...HIDE,
        // ...Y(40),
    })

    return Object.assign(base, {
        show: () => {
            base.style({ ...Y(0), ...SHOW })
        },
        hide: () => {
            base.style({ ...Y(40), ...HIDE })
        }
    })
}