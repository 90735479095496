import { Ghost, ghostify } from '../../../base/components/advanced/ghost/ghost'
import { Base } from '../../../base/components/base'
import emitter from '../../../base/utils/emitter'
import { MenuIconBar } from './menu-icon-bar'
import { CENTER, EASE, HIDE, SHOW, Y } from '../../../base/helpers/style'
import configs from '../../../configs'
import router from '../../../base/lib/router'

export const MenuIcon = () => {
    const isMobile = true//device.isMobile()

    const height = 3
    const base = Base()
    const top = MenuIconBar(height)
    const middle = MenuIconBar(height)
    const bottom = MenuIconBar(height)


    router.on('change', (r: any) => r.path == '/' ? show() : hide())
        
    base.el.onclick = () => {
        router.goto('/menu/23/test/best?a=1&b=2', {force: true})
    }

    ghostify(base, {bg: 'white'});
    base.append(top, middle, bottom)

    base.cssClass({
        position: 'fixed',
        left: '0',
        top: 'calc(0px + env(safe-area-inset-top))',
        // backgroundColor: 'gray',
        width: '64px',
        height: '64px',
        padding: '22px',
        ...EASE(isMobile ? .16: 0),
        zIndex: '99999',
        ...CENTER,
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.8'
        },
        '&:active': {
            opacity: '.6'
        }
    })

    function collapse() {
        top.style({
            top: '14px',
            right: '17px',
            transform: `rotateZ(-45deg)`
        })
        middle.style({
            right: '10px'
        })
        bottom.style({
            bottom: '14px',
            right: '17px',
            transform: `rotateZ(45deg)`
        })
    }
    function show() {
        base.style({ // todo: fix opacity issue
            ...SHOW,
            ...Y(0)
        })
    }
    function hide() {
        base.style({
            ...HIDE,
            ...Y(0)
        })
    }

    function expand() {
        top.style({
            top: 11 + 'px',
            right: '10px',
            transform: `rotateZ(0deg)`
        })
        middle.style({
            top: (configs.sizes.TOP_MARGIN - 3 * height) / 2 + height + 10 + 'px',
            right: '14px'
        })
        bottom.style({
            bottom: 11 + 'px',
            right: '8px',
            transform: `rotateZ(0deg)`
        })

    }
    return {
        ...base,
        collapse,
        expand

    }
}