import { Div } from '../../../../base/components/native/div'
import { IOption, IQuestion } from '../../../../liumus-interfaces/quiz'

export const AttemptQuestion = (q: IQuestion, answers: number[], index: number) => {

    const base = Div()
    const title = Div((index + 1) + '. ' + q.title)
    const options = Div()
    base.append(title, options)

    const count: any = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
    }
    answers.forEach(a => {
        count[a]++
    })
    console.log(count, 'before calc')

    q.options.forEach((o, i) => {
        const option = AttemptQuestionOption(o, i)
        options.append(option)
        const color = Math.round((count[i] / answers.length) * 64)
        console.log({color, count, answers, i});

        // convert to hex
        const hex = ('0' + color.toString(16)).slice(-2)
        console.log(hex, 'hex');
console.log(o.isCorrect? ('#00c853' + hex):( '#ed143d' + hex) );
console.log('---');


        option.style({ 
            backgroundColor:o.isCorrect? ('#00c853' + hex):( '#ed143d' + hex) 
        })
    })

    title.cssClass({
        fontWeight: '500',
        fontSize: '18px',
    })
    base.cssClass({
        marginBottom: '40px',
    })

    return base
}

function AttemptQuestionOption(o: IOption, i: number): any {
    const base = Div(o.title)
    const check = Div('✓')
    if (o.isCorrect) base.prepend(check)
    check.cssClass({
        position: 'absolute',
        left: '-20px'
    })
    base.cssClass({
        color: 'gray',
        margin: '10px 40px',
        backgroundColor: '#fff',
        borderRadius: '15px',
        width: 'fit-content',
        padding: '5px 10px',
        position: 'relative',
    })
    return base
}