import { Editable } from '../../../base/components/advanced/input/editable'
import { Div } from '../../../base/components/native/div'
import { CENTER, HIDE, SHOW } from '../../../base/helpers/style'
import { IOption } from '../../../liumus-interfaces/quiz'

export const Option = (o: IOption) => {

    const base = Div()
    const title = Editable({ text: o.title, selectOnClick: true, removeFormattingOnPaste: true })
    const circle = Div()
    const correct = Div('✓').style(HIDE)
    circle.append(correct)
    base.append(circle, title)
    if (o.isCorrect) correct.style(SHOW)

    title.on('blur', (text: string) => {
        if (text === '') return title.el.innerHTML = o.title
        console.log('option title changed', text)
        o.title = text
    })
    // title on escape


    circle.el.onclick = () => base.emit('click')
    circle.cssClass({
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '1px solid #bbb',
        marginRight: '10px',
        cursor: 'pointer',
        ...CENTER,
        '&:hover': {
            backgroundColor: '#eee'
        }
    })
    base.cssClass({
        display: 'flex',
        margin: '24px 16px',
        color: '#222'
    })

    return Object.assign(base, {
        select() {
            correct.style(SHOW)
            o.isCorrect = true
        },
        unselect() {
            correct.style(HIDE)
            o.isCorrect = false
        },
        isSelected() {
            return o.isCorrect
        },
    })
}