import { Div } from '../../../../base/components/native/div'
import { CENTER, WH } from '../../../../base/helpers/style'

export const PlusOption = (title: string) => {

    const base = Div(title)
    base.cssClass({
        cursor: 'pointer',
        fontSize: '16px',
        color: '#333',
        backgroundColor: '#fff',
        border:'1px solid gray',
        borderRadius: '21px',
        margin: '5px 5px',
        ...WH(160, 42),
        ...CENTER,
        '&:hover': {
            opacity: '.9',
        },
        '&:active': {
            opacity: '.8',
        }

    })

    return base
}