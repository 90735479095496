import { Div } from '../../../../base/components/native/div'

export const SubPageContents = () => {

    const base = Div()

    base.cssClass({
        padding: '55px',
        overflowY: 'scroll',
        height: 'calc(100vh - 64px)',
        width: '100%',
        position: 'relative',
    })

    return base
}