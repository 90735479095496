import { Div } from '../../../../base/components/native/div'

export const CourseAttemptees = () => {

    const base = Div()
    base.cssClass({
        overflow: 'scroll',
        overflowX: 'hidden',
        borderRight: '1px solid #ccc',
        marginTop: '10px',
        marginBottom: '10px',
        height: 'calc(100% - 80px)',
        flexShrink: '0',
        padding: '32px 15px'
    })

    return base
}