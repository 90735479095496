import { Div } from '../../../base/components/native/div'
import { shortUUID } from '../../../base/utils/id-generator'
import { IQuestion } from '../../../liumus-interfaces/quiz'
import { AddQuestionButton } from './add-question-button'
import { Question } from './question'

export const Questions = (questions: IQuestion[] = [], isAdmin = true) => {

    const base = Div()
    const container = Div()
    const add = AddQuestionButton()
    base.append(container)
    if (isAdmin) base.append(add)

    add.el.onclick = handleAddQuestion

    container.cssClass({
        // marginBottom: '50px',
    })
    base.cssClass({
        marginBottom: '50px',
        marginLeft: isAdmin ? '0' : '-30px',
    })

    base.on('mounted', () => {
        render()
    })

    function handleAddQuestion() {
        const q: IQuestion = {
            title: 'Question title (click to edit)',
            options: [
                { title: 'Option 1', isCorrect: true },
                { title: 'Option 2', isCorrect: false },
                { title: 'Option 3', isCorrect: false },
                { title: 'Option 4', isCorrect: false },
            ],
            id: shortUUID()
        }
        questions.push(q)
        const question = Question(q, questions.length)
        question.on('remove', () => {
            questions = questions.filter(_q => _q.id !== q.id)
            render()
        })
        container.append(question)
    }

    function getValue() {
        return questions
    }

    function render() {
        container.empty()
        questions.forEach((q, i) => {
            const question = Question(q, i + 1, isAdmin)
            question.on('remove', () => {
                questions = questions.filter(_q => _q.id !== q.id)
                render()
            })
            container.append(question)
        })
    }

    return Object.assign(base, { getValue })
}