import { Base } from '../../../../base/components/base'
import { Y, X } from '../../../../base/helpers/style'
import { IRouteParams } from '../../../../base/lib/router'
import { waitFor } from '../../../../base/utils/wait'
import helpers from '../../../../helpers'
import services from '../../../../services'
import { CourseOptions } from './course-options'

export const CourseOptionsPage = () => {
    
    const base = Base()
    const options = CourseOptions('mobile')
    base.append(options)

    base.cssClass({
        ...helpers.styles.PAGE_BASE_STYLE,
        position: 'relative'
    })


    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT_LEFT_STYLE)
        if (to === '/courses') base.style(helpers.styles.PAGE_EXIT_DOWN_STYLE)
    }

    async function enter({ params, from, data }: IRouteParams) {
        console.log('entering course options page', data);
        options.applyRoles(data.roles)
        if (helpers.device.isMobile()) await waitFor(100)
        base.style(helpers.styles.PAGE_ENTER_STYLE)
    }

    return Object.assign(base, { enter, exit })
}